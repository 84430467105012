export default [
  {
    name: `common_assets`,
    backButton: require(`@/assets/icons/arrow_left_white.svg`),
    backgroundImage: require(`@/assets/background/background_3.png`),
  },
  {
    name: `greeting_page`,
    shoeImage: require(`@/assets/products/flying_shoe.png`),
    sparkle: require(`@/assets/splashscreen_loading/sparkles.png`),
    logo: require(`@/assets/logos/flow.png`),
  },
  {
    name: `game_form`,
    mailerBaseUrl: `https://mailer.reality-spaces.tv/send/`,
    clientUUID: `19512bed-467e-4c9c-a963-45a445867535`,
    companyUUID: `bfeca143-e3ae-4382-b7e9-76d41d9e81c6`,
    companyEmail: `kalenjijeu@decathlon.com`,
    fields: [
      {
        name: `firstname`,
        type: `text`,
      },
      {
        name: `lastname`,
        type: `text`,
      },
      {
        name: `email`,
        type: `email`,
      },
    ],
  },
];
