
export default [
  {
    name: `background_image`,
    image: require(`@/assets/background/background_mobile.jpg`)
  },
  {
    name: `background_elements`,
    elems: [
      {
        name: `cloud_one`,
        context: [`none`],
      },
      {
        name: `cloud_two`,
        styles: {
          left: `calc((100vw - 100%) - 130%)`,
          bottom: `-35vh`,
          width: `300%`,
          height: `auto`,
        },
      },
      {
        name: `cloud_three`,
        context: [`none`],
      },
      {
        name: `person`,
        styles: {
          'z-index': `10`,
          right: `-10%`,
          top: `50%`,
          width: `auto`,
          height: `70%`,
        },
      },
      {
        name: `neon`,
        context: [`lang`],
        styles: {
          'z-index': `10`,
          right: `-25%`,
          top: `50%`,
          width: `70%`,
          height: `auto`,
          transform: `rotate(45deg)`,
        },
      },
    ]
  },
];