
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `仅重200克`,
      content: `为了让迈出的每一步都更轻松自如，Jogflow 500.1的净重只有200克！`,
    },
    {
      name: `ventilated-mesh`,
      title: `透气网面`,
      content: `跑鞋采用了非常透气的网面设计，可让双脚更觉清爽，让跑步者在运动时也能拥有干爽的舒适感受。`,
    },
    {
      name: `4mm-drop`,
      title: `4毫米缓冲厚度`,
      content: `Kalenji的Jogflow 500.1跑鞋拥有4毫米的缓冲厚度，跑步者的前脚掌可放心地碰击地面，该设计可帮助用户以更自然的步伐跑步，同时避免受伤。`,
    },
    {
      name: `eva-foam`,
      title: `EVA泡沫`,
      content: `为了避免足部在鞋底陷入过深，设计团队还打造出了质地柔软的EVA泡沫缓冲鞋垫，以减少对脚踝的冲击力度。`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H鞋底 `,
      content: `Jogflow 500.1跑鞋采用了Flex H鞋底，可最大程度地保持跑鞋的柔韧度。鞋底上的可弯曲槽口贴合双脚迈出的每一个步伐，让足部的行动更自由，同时提供减震效果。`,
    },
  ]
};
