
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Eco-Concepção`,
      content: `A Kalenji usa materiais reciclados para a concepção da mesh de rede superior, bem como para as solas interiores e exteriores. A mesh de rede supeior foi 62% eco-concebida!`,
    },
    {
      name: `3d-knit`,
      title: `MESH DE REDE 3D`,
      content: `A Kalenji concebeu uma mesh de rede 3D superior que oferece um verdadeiro efeito de "segunda pele" ao corredor que os usar.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop de 4mm`,
      content: `A Kalenji oferece um drop de 4 milímetros em seus Jogflow 500.1 para convidar o corredor a atacar o solo com a parte da frente do pé, ajudando-o a a adotar uma passada mais natural e limitar o risco de lesões.`,
    },
    {
      name: `eva-foam`,
      title: `Espuma EVA`,
      content: `Para evitar que o pé se afunde demasiado na palmilha, as equipes de design criaram uma espuma de amortecimento em EVA para reduzir o impacto no calcanhar.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Sole`,
      content: `Os Jogflow 500.1 beneficiam de uma sola Flex H, concebida para dar ao sapato a maior flexibilidade possível. Estes entalhes flexíveis na sola permitem uma boa passada e proporcionam liberdade de movimentos do pé, enquanto garantem a sua função natural de amortecedor.`,
    },
  ]
};
