import * as THREE from 'three'

export default class Pointer {
	constructor(ap3) {
		this.init(ap3)
	}

	init(ap3) {
		this.ap3 = ap3
		this.pointing = false
		this.dragging = false
		this.pointer = new THREE.Vector2(0, 0)
		this.iPointer = new THREE.Vector2(0, 0)
		this.dPointer = new THREE.Vector2(0, 0)

		this.ap3.canvas.addEventListener('touchstart', (e) => { this.startPointing(e.touches[0].clientX, e.touches[0].clientY) })
		this.ap3.canvas.addEventListener('touchend', () => { this.stopPointing() })
		this.ap3.canvas.addEventListener('touchmove', (e) => { this.setPointer(e.touches[0].clientX, e.touches[0].clientY) })

		this.ap3.canvas.addEventListener('mousedown', (e) => {
			if (e.which === 1 && e.ctrlKey !== true) {
				this.startPointing(e.clientX, e.clientY);
				// window.addEventListener('mouseup', () => { this.stopPointing() })
				// window.addEventListener('mousemove', (e) => { this.setPointer(e.clientX, e.clientY) })
			}
		})
		this.ap3.canvas.addEventListener('mouseup', () => { this.stopPointing() })
		this.ap3.canvas.addEventListener('mousemove', (e) => { this.setPointer(e.clientX, e.clientY) })
	}

	startPointing(x, y) {
		this.ap3.shoes.trigger(`clicked`)
		this.setPointer(x, y)
		this.iPointer.x = this.pointer.x
		this.iPointer.y = this.pointer.y
		this.pointing = true
		this.ap3.time.addEvent('pointing')
	}

	stopPointing() { if(this.pointing) {
		this.pointing = false
		this.dragging = false
		this.ap3.time.remEvent('pointing')
		this.ap3.time.remEvent('dragging')
	}}

	setPointer(x, y) {
		this.pointer.x = ((x / this.ap3.sizes.w) - 0.5) * 2
		this.pointer.y = -((y / this.ap3.sizes.h) - 0.5) * 2
	}

	tickPointing() {
		if(!this.dragging && Math.sqrt((Math.pow((this.pointer.x - this.iPointer.x), 2) + Math.pow((this.pointer.y - this.iPointer.y) * this.ap3.sizes.h / this.ap3.sizes.w, 2)) / 4) > this.ap3.action.params.safeZoneFactor/100) {
			this.iPointer.x = this.pointer.x
			this.iPointer.y = this.pointer.y
			this.ap3.action.setITarget()
			this.ap3.time.addEvent('dragging')
			this.ap3.time.addEvent('rig')
			this.dragging = true
		}
	}
}