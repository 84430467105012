var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"splash-screen",class:{
    visible: _vm.visible,
    mobile: _vm.$breakpoints.isMobile,
  }},[_c('clouds-background',{attrs:{"clouds-config":_vm.cloudsConfig,"context":_vm.splashContext}},[_c('div',{class:{
        mobile: _vm.$breakpoints.isMobile,
      },attrs:{"id":"splash-content-container"}},[(_vm.$breakpoints.isMobile)?_c('lang-selector-mobile',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLangSelectorMobileOpened),expression:"isLangSelectorMobileOpened"}],on:{"close":function($event){_vm.isLangSelectorMobileOpened = false},"choose-lang":_vm.chooseLang}}):_c('lang-selector-desktop',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLangSelectorDesktopOpened),expression:"isLangSelectorDesktopOpened"}],on:{"close":function($event){_vm.isLangSelectorDesktopOpened = false},"choose-lang":_vm.chooseLang}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLangSelectorMobileOpened && !_vm.isLangSelectorDesktopOpened),expression:"!isLangSelectorMobileOpened && !isLangSelectorDesktopOpened"}],staticClass:"w-100 flex-col flex-grow justify-center align-center"},[_c('div',{staticClass:"w-100 flex-row justify-end px-20 py-20",class:{
            'px-30': !_vm.$breakpoints.isMobile,
            'py-30': !_vm.$breakpoints.isMobile,
          }},[_c('a',{staticClass:"kalenji-button white-button fab-button",on:{"click":_vm.openLangSelector}},[_vm._v(" "+_vm._s(_vm.lang.shortLabel)+" ")])]),_c('div',{staticClass:"w-100 flex-col flex-grow justify-space-between align-center pt-30 pb-50",attrs:{"id":"splash-main-content"}},[_c('div',{staticClass:"flex-row justify-center",attrs:{"id":"flowLogo"}},[_c('img',{attrs:{"src":_vm.flowLogo,"alt":"flow-logo"}})]),_c('div',{staticClass:"w-100 flex-row justify-space-between align-center",attrs:{"id":"kalenjiLogo"}},[_c('div',{staticClass:"line"}),_c('img',{attrs:{"src":_vm.kalenjiLogo,"alt":"kalenji-logo"}}),_c('div',{staticClass:"line"})]),_c('a',{staticClass:"kalenji-button white-button large-button",on:{"click":_vm.start}},[_vm._v(" "+_vm._s(_vm.startCTA)+" ")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }