
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Ecodesign`,
      content: `Kalenji verwendet für das Strickgewebe am Schaft sowie für die Innen- und Außensohle Recyclingmaterialien. Das Strickgewebe am Schaft ist zu 62 % umweltfreundlich konzipiert!`,
    },
    {
      name: `3d-knit`,
      title: `3D-STRICK`,
      content: `Kalenji hat einen Schaft aus 3D-Strick entwickelt, der sich beim Laufen anfühlt wie eine zweite Haut.`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `Kalenji hat seinen JogFlow 500.1 mit einem Drop von 4 mm ausgestattet, damit der Läufer animiert wird, mit dem Vorfuß zuerst aufzusetzen. Das bewirkt ein natürlicheres Abrollen und verringert die Verletzungsgefahr.`,
    },
    {
      name: `eva-foam`,
      title: `EVA-Schaum`,
      content: `Damit der Fuß nicht zu weit in die Sohle einsinkt, haben die Design-Teams ein weiches Polster aus EVA-Schaum konzipiert. Dadurch setzt die Ferse nicht so hart auf.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H-Sohle`,
      content: `Der JogFlow 500.1 ist mit einer Flex H-Sohle ausgestattet, sodass der Schuh größtmögliche Flexibilität gewährt. Die Flexkerben an der Sohle erleichtern das Abrollen und fördern die Bewegungsfreiheit des Fußes. Gleichzeitig sorgen sie dafür, dass der Fuß seine natürliche Aufgabe als Stoßdämpfer übernimmt.`,
    },
  ]
};