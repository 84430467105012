<template>
  <div
    class="splash-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <clouds-background
      :clouds-config="cloudsConfig"
      :context="splashContext"
    >
      <div
        id="splash-content-container"
        :class="{
          mobile: $breakpoints.isMobile,
        }"
      >
        <lang-selector-mobile
          @close="isLangSelectorMobileOpened = false"
          @choose-lang="chooseLang"
          v-show="isLangSelectorMobileOpened"
          v-if="$breakpoints.isMobile"
        />
        <lang-selector-desktop
          @close="isLangSelectorDesktopOpened = false"
          @choose-lang="chooseLang"
          v-show="isLangSelectorDesktopOpened"
          v-else
        />
        <div
          class="w-100 flex-col flex-grow justify-center align-center"
          v-show="!isLangSelectorMobileOpened && !isLangSelectorDesktopOpened"
        >
          <div
            class="w-100 flex-row justify-end px-20 py-20"
            :class="{
              'px-30': !$breakpoints.isMobile,
              'py-30': !$breakpoints.isMobile,
            }"
          >
            <a class="kalenji-button white-button fab-button" @click="openLangSelector">
              {{ lang.shortLabel }}
            </a>
          </div>
          <div id="splash-main-content" class="w-100 flex-col flex-grow justify-space-between align-center pt-30 pb-50">
            <div id="flowLogo" class="flex-row justify-center">
              <img :src="flowLogo" alt="flow-logo">
            </div>
            <div id="kalenjiLogo" class="w-100 flex-row justify-space-between align-center">
              <div class="line"></div>
              <img :src="kalenjiLogo" alt="kalenji-logo" />
              <div class="line"></div>
            </div>
            <a class="kalenji-button white-button large-button" @click="start">
              {{ startCTA }}
            </a>
          </div>
        </div>
      </div>
    </clouds-background>
  </div>
</template>

<script>
import LangSelectorMobile from '@/components/LangSelectorMobile';
import LangSelectorDesktop from '@/components/LangSelectorDesktop';
import CloudsBackground from '@/components/CloudsBackground';

export default {
  components: {
    CloudsBackground,
    LangSelectorMobile,
    LangSelectorDesktop,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    cloudsConfig: {
      type: Array,
      required: true,
      default: () => [],
    },
    splashscreenConfig: {
      type: Array,
      required: false,
      default: () => [],
    },
    lang: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isLangSelectorMobileOpened: false,
    isLangSelectorDesktopOpened: false,
  }),

  computed: {
    splashContext() {
      return (this.isLangSelectorMobileOpened || this.isLangSelectorDesktopOpened ? `lang` : `default`);
    },
    startCTA() {
      return this.getConfigItem(`start_cta`).label || ``;
    },
    flowLogo() {
      return this.getConfigItem(`flow_logo`).image || ``;
    },
    kalenjiLogo() {
      return this.getConfigItem(`kalenji_logo`).image || ``;
    },
  },

  methods: {
    getConfigItem(name) {
      return this.splashscreenConfig.find(item => item.name === name) || null;
    },
    openLangSelector() {
      this.isLangSelectorMobileOpened = this.$breakpoints.isMobile;
      this.isLangSelectorDesktopOpened = !this.isLangSelectorMobileOpened;
    },
    chooseLang(lang) {
      this.$emit(`choose-lang`, lang);
    },
    start() {
      this.$gtagEvent(`Start_Experience`);
      this.$emit(`close`);
    },
  },
};
</script>

<style lang="scss" scoped>
.splash-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  & {
    color: #FFF;
  }

  #splash-content-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      border: 2px solid #fff;
      border-radius: 100px;
      background: #fff;
      color: #ae9e91;
      transition: background .2s, color .2s;

      &:hover {
        color: #fff;
        background: #ae9e91;
      }
      &:focus {
        outline: none;
      }
    }

    #flowLogo {
      img {
        width: 50%;
        filter: brightness(0) invert(1);
      }
    }

    #kalenjiLogo {
      padding-bottom: 15%;

      .line {
        flex-grow: 1;
        height: 2px;
        background: #fff;
      }

      img {
        padding: 0 20px;
        width: 50%;
      }
    }

    &:not(.mobile) {
      .kalenji-button {
        font-size: 24px;
        padding-top: 1.3em;
        padding-bottom: 1.3em;

        &.fab-button {
          font-size: 30px;
          padding: 1.3em;
        }
      }

      #splash-main-content {
        width: 50%;
        padding-bottom: 100px !important;

        #flowLogo {
          img {
            width: 25%;
            min-width: 170px;
          }
        }

        #kalenjiLogo {
          justify-content: center;

          .line {
            display: none;
          }

          img {
            width: 50%;
            min-width: 350px;
          }
        }
      }
    }
  }
}
</style>
