
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Slechts 200g`,
      content: `Om het maximale uit iedere stap te halen weegt deze schoen slechts 200 grams!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Ventilerende Mesh`,
      content: `Het super ventilerende mesh zorgt voor een optimaal ademend vermogen voor de voet en nodigt uit om te genieten van ieder moment als je aan het lopen bent!`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `De Jogflow 500K.1 van Kalenji heeft een drop van 4 millimeter om de loper uit te nodigen om op de voorvoet te landen. Dit bevordert het natuurlijke looppatroon en verlaagt de kans op blessures.`,
    },
    {
      name: `eva-foam`,
      title: `EVA Schuim`,
      content: `Om te voorkomen dat de voet teveel wegzakt in de zool heeft het design team een zacht EVA schuim ontwikkeld die de landing optimaal dempt bij iedere stap.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Zool`,
      content: `De Jogflow 500K.1 heeft een Flex H zool, die ontworpen is om de schoen zo soepel mogelijk te maken. De inkepingen in de zool zorgen voor een optimale bewegingsvrijheid van de voet en dit zorgt voor een natuurlijke demping.`,
    },
  ]
};