
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Nur 200 g`,
      content: `Um aus jedem Schritt das Maximum herauszuholen, wiegt der JogFlow 500.1 nur 200 Gramm!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Belüftetes Mesh`,
      content: `Das extra gut belüftete Mesh-Gewebe ermöglicht mehr Atmungsaktivität am Fuß und erhöht durch ein trockenes Tragegefühl den Spaß am Laufen.`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `Kalenji hat seinen JogFlow 500.1 mit einem Drop von 4 mm ausgestattet, damit der Läufer animiert wird, mit dem Vorfuß zuerst aufzusetzen. Das bewirkt ein natürlicheres Abrollen und verringert die Verletzungsgefahr.`,
    },
    {
      name: `eva-foam`,
      title: `EVA-Schaum`,
      content: `Damit der Fuß nicht zu weit in die Sohle einsinkt, haben die Design-Teams ein weiches Polster aus EVA-Schaum konzipiert. Dadurch setzt die Ferse nicht so hart auf.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H-Sohle`,
      content: `Der JogFlow 500.1 ist mit einer Flex H-Sohle ausgestattet, sodass der Schuh größtmögliche Flexibilität gewährt. Die Flexkerben an der Sohle erleichtern das Abrollen und fördern die Bewegungsfreiheit des Fußes. Gleichzeitig sorgen sie dafür, dass der Fuß seine natürliche Aufgabe als Stoßdämpfer übernimmt.`,
    },
  ]
};