
export default [
  {
    key: `fr_FR`,
    shortLabel: `FR`,
    label: `French`,
  },
  {
    key: `en_US`,
    shortLabel: `EN`,
    label: `English`,
    isDefault: true,
  },
  {
    key: `es_ES`,
    shortLabel: `ES`,
    label: `Spanish`,
  },
  {
    key: `de_DE`,
    shortLabel: `DE`,
    label: `German`,
  },
  {
    key: `ch_TW`,
    shortLabel: `CH`,
    label: `Chinese`,
  },
  {
    key: `br_BR`,
    shortLabel: `BR`,
    label: `Brazilian`,
  },
  {
    key: `it_IT`,
    shortLabel: `IT`,
    label: `Italian`,
  },
  {
    key: `pl_PL`,
    shortLabel: `PO`,
    label: `Poland`,
  },
  {
    key: `nl_NL`,
    shortLabel: `NL`,
    label: `Dutch`,
  },
]
