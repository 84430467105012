
export default {
  envMaps: [
    { url: require(`@/assets/env-maps/px.jpg`) },
    { url: require(`@/assets/env-maps/nx.jpg`) },
    { url: require(`@/assets/env-maps/py.jpg`) },
    { url: require(`@/assets/env-maps/ny.jpg`) },
    { url: require(`@/assets/env-maps/pz.jpg`) },
    { url: require(`@/assets/env-maps/nz.jpg`) },
  ],
  assets: [
    { name: 'shoes', type: 'glb', url: require(`@/assets/glbs/shoes.glb`) },
    { name: 'aCrampon_N', type: 'tex', url: require(`@/assets/texs/aCrampon_N.jpg`), flip: true },
    { name: 'aLanguette_02_D', type: 'tex', url: require(`@/assets/texs/aLanguette_02_D.jpg`), flip: true },
    { name: 'aSemelle_02_N', type: 'tex', url: require(`@/assets/texs/aSemelle_02_N.jpg`), flip: true },
    { name: 'aSemelle_03_N', type: 'tex', url: require(`@/assets/texs/aSemelle_03_N.jpg`), flip: true },
    { name: 'aTissu_01_D', type: 'tex', url: require(`@/assets/texs/aTissu_01_D.jpg`), flip: true },
    { name: 'aTissu_02_D', type: 'tex', url: require(`@/assets/texs/aTissu_02_D.jpg`), flip: true },
    { name: 'bLacet_D', type: 'tex', url: require(`@/assets/texs/bLacet_D.jpg`), flip: true },
    { name: 'bLanguette_01_D', type: 'tex', url: require(`@/assets/texs/bLanguette_01_D.jpg`), flip: true },
    { name: 'bLanguette_01_N', type: 'tex', url: require(`@/assets/texs/bLanguette_01_N.jpg`), flip: true },
    { name: 'bSemelle_01_N', type: 'tex', url: require(`@/assets/texs/bSemelle_01_N.jpg`), flip: true },
    { name: 'bTissu_01_N', type: 'tex', url: require(`@/assets/texs/bTissu_01_N.jpg`), flip: true },
    { name: 'bTissu_02_D', type: 'tex', url: require(`@/assets/texs/bTissu_02_D.jpg`), flip: true },
    { name: 'bTissu_03_N', type: 'tex', url: require(`@/assets/texs/bTissu_03_N.jpg`), flip: true },

    {
      name: 'blanc',
      type:'matStd',
      color: 0Xffffff,
      envFac: 0.75
    }, {
      name: 'aCrampon',
      type:'matStd',
      color: 0X111111, normal: 'aCrampon_N',
      envFac: 2
    }, {
      name: 'aLanguette_02',
      type:'matStd',
      diffuse: 'aLanguette_02_D',
      envFac: 0.75
    }, {
      name: 'aMorceau_01',
      type:'matStd',
      color: 0X2B5DB3,
      envFac: 0.75
    }, {
      name: 'aMorceau_02',
      type:'matStd',
      color: 0XFFC09C,
      envFac: 0.75
    }, {
      name: 'aSemelle_02',
      type:'matStd',
      normal: 'aSemelle_02_N',
      envFac: 0.5
    }, {
      name: 'aSemelle_03',
      type:'matStd',
      normal: 'aSemelle_03_N',
      envFac: 0.75
    }, {
      name: 'aSemelle_04',
      type:'matStd',
      color: 0X607172,
      envFac: 0.75
    }, {
      name: 'aSemelleIn',
      type:'matStd',
      color: 0XA0A0A0,
      normal: 'aSemelle_02_N',
      envFac: 0.15
    }, {
      name: 'aTissu_01',
      type:'matStd',
      diffuse: 'aTissu_01_D',
      envFac: 0.75
    }, {
      name: 'aTissu_02',
      type:'matStd',
      diffuse: 'aTissu_02_D',
      envFac: 0.75
    },

    {
      name: 'bCouture',
      type:'matStd',
      color: 0X8FA785,
      envFac: 0.5
    }, {
      name: 'bLacet',
      type:'matStd',
      diffuse: 'bLacet_D',
      envFac: 1
    }, {
      name: 'bLanguette_01',
      type:'matStd',
      diffuse: 'bLanguette_01_D', normal: 'bLanguette_01_N',
      envFac: 0.5
    }, {
      name: 'bLanguette_02',
      type:'matStd',
      color: 0X434640, //0X415047
      envFac: 0.75
    }, {
      name: 'bLigne_02',
      type:'matStd',
      color: 0X4E4E39,
      envFac: 0.5
    }, {
      name: 'bMorceau_02',
      type:'matStd',
      color: 0XB6F099,
      envFac: 0.25
    }, {
      name: 'bMorceau_03',
      type:'matStd',
      color: 0XFFC09C,
      envFac: 0.25
    }, {
      name: 'bSemelle_01',
      type:'matStd',
      normal: 'bSemelle_01_N',
      envFac: 0.75
    }, {
      name: 'bSemelleIn',
      type:'matStd',
      color: 0XB7FF97,
      envFac: 0.05
    }, {
      name: 'bTissu_01',
      type:'matStd',
      color: 0X434640, normal: 'bTissu_01_N',
      envFac: 0.75
    }, {
      name: 'bTissu_02',
      type:'matStd',
      diffuse: 'bTissu_02_D',
      envFac: 0.75
    }, {
      name: 'bTissu_03',
      type:'matStd',
      color: 0X4E4E39, normal: 'bTissu_03_N',
      envFac: 1
    }, {
      name: 'bTissuIn',
      type:'matStd',
      color: 0X2D3226,
      envFac: 1
    }
  ],
};
