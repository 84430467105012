export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Only 200g`,
      content: `Per permettere di dare il massimo ad ogni falcata, la Jogflow 500.1 pesa solo 200 grammi!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Mesh ventilata`,
      content: `La mesh ultra-ventilata permette la più grande respirabilità del piede e porta il runner ad approfittare appieno dell’istante, restando asciutto.`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `Sulle sue Jogflow 500K.1 Kalenji propone un drop di 4 millimetri per  portare il runner ad attaccare il suolo con l’avampiede, aiutandolo ad adottare una falcata più naturale e limitare così il rischio di infortuni.`,
    },
    {
      name: `eva-foam`,
      title: `Schiuma EVA`,
      content: `Per evitare che il piede affondi troppo nella suola, le squadre di progettazione hanno creato una morbida ammortizzazione in schiuma EVA per ridurre l'impatto del tallone.`,
    },
    {
      name: `flex-h-sole`,
      title: `Suola Flex H`,
      content: `La Jogflow 500K.1 beneficia della suola Flex H, disegnata per mantenere il più flessibile possibile la scarpa. Questa scanalatura flessibile della suola permette ogni falcata e apporta libertà di movimento al piede, assicurando nel contempo il suo ruolo naturale di ammortizzatore degli impatti.`,
    },
  ]
};