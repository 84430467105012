export default [
  {
    name: `introduction_tutorial`,
    content: `Explorer sur 360°`,
    cta: `Continuer`,
  },
  {
    name: `game_tutorial`,
    title: `ET SI ON JOUAIT ?`,
    content: `Cliquez ici pour trouver la Jogflow dans les nuages et tenter de gagner une tenue Kalenji`,
    cta: `Continuer`,
  },
]
