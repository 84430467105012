<template>
  <div id="spaces-visit" :class="{ visible }">
    <spaces-scene
      :scene="currentScene"
      :visible="isSceneVisible"
      :do-tick="isSceneTicking"
      :hotspots-visible="areHotspotsVisible"
      :muted="muted || isSceneMuted"
      :gyro-enabled="gyroEnabled"
      :render="render"
      :gyro-datas="gyroDatas"
      :is-in-game="isGameOpened"
      @scene-mounted="sceneMounted(currentScene)"
      @play-sfx="playSoundEffect"
      @open-dialog="openDialog"
      @open-video="openSphereVideo"
      @open-shoe="openShoe"
      @open-game="openGame"
      @close-game="closeGame"
      @lose-game="showLoseMessage"
      @open-game-modal="openGameModal"
      v-if="currentScene"
    />
    <sphere-video
      :video="currentSphereVideo"
      :visible="isSphereVideoOpened"
      @close="closeSphereVideo"
    />
    <intro-tutorial
      :visible="currentTutorial === `introduction_tutorial` && isTutorialOpened"
      :config="getTutorialByName(`introduction_tutorial`)"
      @close-tutorial="closeTutorial"
    />
    <game-tutorial
      :visible="currentTutorial === `game_tutorial` && isTutorialOpened"
      :config="getTutorialByName(`game_tutorial`)"
      @close-tutorial="closeTutorial"
    />
    <shoe-presentation
      :visible="isShoePresentationOpened"
      :product-page-opened="isProductPageOpened"
      :presentation-config="shoePresentationConfig"
      :shoes3d-config="visit.shoes3D"
      @play-sfx="playSoundEffect"
      @close-shoe-presentation="closeShoe"
      @open-product-page="isProductPageOpened = true"
    />
    <product-page
      :visible="isProductPageOpened"
      :products-config="products"
      :lang="lang"
      @play-sfx="playSoundEffect"
      @close-product-page="isProductPageOpened = false"
    />
    <game-modal
      :visible="isGameModalOpen"
      :lang="lang"
      :game-config="gameConfig"
      @close-game-modal="closeGameModal"
    />
    <div
      class="lose-message"
      :class="{
        visible: isLoseMessageVisible,
      }"
      :style="`
        top:  ${loseMessageCoords.y}%;
        left: ${loseMessageCoords.x}%;
      `"
    >
      {{ loseMessage }}
    </div>
  </div>
</template>

<script>
import SpacesScene from '@/components/SpacesScene';
import IntroTutorial from '@/components/Tutorials/IntroTutorial';
import GameTutorial from '@/components/Tutorials/GameTutorial';
import GameModal from '@/components/GameModal';
import ShoePresentation from '@/components/ShoePresentation';
import SphereVideo from '@/components/SphereVideo';
import ProductPage from '@/components/ProductPage';

export default {
  components: {
    SpacesScene,
    IntroTutorial,
    GameTutorial,
    GameModal,
    ShoePresentation,
    SphereVideo,
    ProductPage,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    visit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    previousDialogName: null,
    currentDialog: null,
    isDialogVisible: false,
    firstSceneName: `main`,
    isChangingScene: false,
    previousSceneName: null,
    currentSceneName: null,
    isMapOpened: false,
    isSceneVisible: false,
    isSceneTicking: false,
    areHotspotsVisible: false,
    isSceneMuted: false,
    isTutorialOpened: true,
    currentTutorial: `introduction_tutorial`,
    isGameModalOpen: false,
    isShoePresentationOpened: false,
    isProductPageOpened: false,
    isSphereVideoOpened: false,
    isGameOpened: false,
    currentSphereVideoName: null,
    isLoseMessageVisible: false,
    canShowLoseMessage: true,
    loseMessageCoords: {x: 0, y: 0},
    loseMessage: ``,
  }),

  computed: {
    scenes() {
      return this.visit?.scenes || [];
    },
    dialogs() {
      return this.visit?.dialogs || [];
    },
    map() {
      return this.visit?.map || [];
    },
    menu() {
      return this.visit?.menu || [];
    },
    tutorials() {
      return this.visit?.tutorials || [];
    },
    products() {
      return this.visit?.products || [];
    },
    shoePresentationConfig() {
      return this.visit?.shoePresentation || { '500': [], '500K': [] };
    },
    gameConfig() {
      return this.visit?.game || [];
    },
    currentScene() {
      let currentScene = null;
      if (this.currentSceneName) {
        currentScene = this.getSceneByName(this.currentSceneName);
      }

      return currentScene || null;
    },
    isCurrentSceneHub() {
      return this.currentScene && this.currentScene.name === `hub`;
    },
    previousScene() {
      let previousScene = null;
      if (this.previousSceneName) {
        previousScene = this.getSceneByName(this.previousSceneName);
      }

      return previousScene || null;
    },
    currentSphereVideo() {
      return this.visit.videos.find(video => video.name === this.currentSphereVideoName) || null;
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    getDialogByName(dialogName) {
      return this.dialogs.find(dialog => dialog.name === dialogName);
    },
    getSceneByName(sceneName) {
      return this.scenes.find(scene => scene.name === sceneName);
    },
    getTutorialByName(tutorialName) {
      return this.tutorials.find(tuto => tuto.name === tutorialName);
    },
    resetZoom() {
      document.body.style.transform = `scale(1)`;
      document.body.style.msTransform = `scale(1)`;
    },
    playSoundEffect(type) {
      this.$emit(`play-sfx`, type);
    },
    pauseSound() {
      this.$emit(`pause-sound`);
    },
    resumeSound() {
      this.$emit(`resume-sound`);
    },
    toggleTutorial() {
      this.isTutorialOpened = !this.isTutorialOpened;
    },
    resumeSoundAfterDialogClose() {
      if (!this.currentScene.hasSound) {
        this.resumeSound();
      }
    },
    async changeScene(sceneName) {
      this.isMapOpened = false;
      this.isDialogVisible = false;

      if (this.isChangingScene || this.currentSceneName === sceneName) { return; }

      this.isChangingScene = true;
      this.areHotspotsVisible = false;

      await this.wait(100);
      this.currentSceneName = sceneName;

      if (this.currentScene.hasSound) {
        this.pauseSound();
      } else {
        this.resumeSound();
      }
    },
    async sceneMounted(scene) {
      if (this.previousScene) {
        this.previousScene.isVisible = false;
        setTimeout(() => {
          this.previousScene.isMounted = false;
        }, 500)
      }
      scene.isVisible = true;
      this.isChangingScene = false;
      await this.wait();
      this.isSceneTicking = true;
      this.isSceneVisible = true;
      this.areHotspotsVisible = true;
    },
    openHub() {
      this.changeScene(`hub`);
    },
    openMap() {
      this.isDialogVisible = false;
      this.isMapOpened = true;
    },
    closeMap() {
      this.isMapOpened = false;
    },
    goHome() {
      this.closeMap();
      if (this.currentSceneName !== this.firstSceneName) {
        this.changeScene(this.firstSceneName);
      }
    },
    async openDialog(dialogName) {
      const dialog = this.getDialogByName(dialogName);
      this.currentDialog = dialog;
      await this.wait();
      if (this.currentDialog.video) {
        this.isSceneMuted = true;
      }
      this.isDialogVisible = true;
    },
    async closeDialog() {
      this.isDialogVisible = false;
      this.isSceneMuted = false;
      if (this.previousDialogName) {
        await this.wait();
        this.openDialog(this.previousDialogName);
      }
      this.previousDialogName = null;
    },
    openShoe() {
      this.isShoePresentationOpened = true;
      this.isSceneTicking = false;
    },
    closeShoe() {
      this.isSceneTicking = true;
      this.isShoePresentationOpened = false;
    },
    openGameTutorial() {
      this.currentTutorial = `game_tutorial`;
      this.isTutorialOpened = true;
    },
    closeTutorial() {
      this.isTutorialOpened = false;
    },
    async openGame() {
      this.$gtagEvent(`Game`);
      this.isSceneVisible = false;
      await this.wait(500);
      this.isGameOpened = true;
      await this.wait(50);
      this.openGameTutorial();
      this.isSceneVisible = true;
    },
    async closeGame() {
      this.isSceneVisible = false;
      await this.wait(500);
      this.isGameOpened = false;
      await this.wait(50);
      this.isSceneVisible = true;
    },
    openGameModal() {
      this.isGameModalOpen = true;
      this.isSceneVisible = false;
    },
    closeGameModal() {
      this.isSceneVisible = true;
      this.isGameModalOpen = false;
      this.closeGame();
    },
    showLoseMessage(coords) {
      const showDelay = 3;

      if (this.canShowLoseMessage) {
        this.loseMessageCoords = coords;
        this.loseMessage = this.getLooseMessage();
        this.isLoseMessageVisible = true;
        this.canShowLoseMessage = false;

        setTimeout(() => {
          this.isLoseMessageVisible = false;
          this.canShowLoseMessage = true;
        }, showDelay * 1000);
      }
    },
    getLooseMessage() {
      const messages = this.gameConfig.find(e => e.name === `lose_messages`)?.messages || [];
      const idx = Math.floor(Math.random() * (messages.length + (messages.length === 1 ? 1 : 0)));

      return messages.length > 0 ? messages[idx] : `Try again !`;
    },
    openSphereVideo(videoName) {
      if (this.currentSphereVideoName === null) {
        this.isSphereVideoOpened = true;
        this.currentSphereVideoName = videoName;
        if (this.currentSphereVideo?.hasSound === true) {
          this.pauseSound();
        }
      }
    },
    closeSphereVideo() {
      this.isSphereVideoOpened = false;
      setTimeout(() => {
        this.resumeSound();
        this.currentSphereVideoName = null;
      }, 500);
    },
  },

  mounted() {
    setTimeout(() => {
      this.changeScene(this.firstSceneName);
      if (!this.currentDialog) {
        this.currentDialog = this.dialogs[0];
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.spaces-visit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lose-message {
  width: fit-content;
  position: absolute;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;

  &.visible {
    opacity: 1;
  }
}

.csr-video-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
