
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Eco design`,
      content: `Do zaprojektowania dzianinowej cholewki oraz podeszwy wewnętrznej i zewnętrznej, marka Kalenji wykorzystała tworzywa pochodzące z recyklingu. Dzianinowa cholewka jest w 62% zaprojektowana ekologicznie!`,
    },
    {
      name: `3d-knit`,
      title: `3D Knit`,
      content: `Marka Kalenji zaprojektowała cholewkę 3D Knit, która zapewnia biegaczowi prawdziwe wrażenie „drugiej skóry”.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop 4 mm`,
      content: `W butach Jogflow 500.1, Kalenji zaproponowało 4-milimetrowy drop, aby zachęcić biegacza do stawiania najpierw przedniej części stopy, dzięki czemu krok jest bardziej naturalny, co ogranicza ryzyko kontuzji.`,
    },
    {
      name: `eva-foam`,
      title: `EVA Foam`,
      content: `Aby zapobiec zbyt głębokiemu zagłębianiu się stopy w bucie podczas biegu, zespoły projektowe stworzyły miękką amortyzację z pianki EVA, która zmniejsza odczuwanie drgań na pięcie.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Sole`,
      content: `Buty Jogflow 500.1 posiadają podeszwę Flex H, zaprojektowaną tak, aby były maksymalnie giętkie. Elastyczne nacięcia na podeszwie reagują na każdy krok, co zwiększa swobodę ruchów stopy. Jednocześnie podeszwa spełnia swoją naturalną rolę amortyzatora.`,
    },
  ]
};