export default [
  {
    name: `greeting_page`,
    content: {
      title: `CONGRATULATIONS`,
      subtitle: `You have a chance to win a Kalenji outfit`,
      cta: `Continue`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Thanks for your participation`,
      subtitle: ``,
      cta: `Return to the showroom`,
    },
  },
  {
    name: `game_form`,
    title: `FILL IN THE FORM TO PARTICIPATE`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Name`,
      },
      {
        name: `lastname`,
        placeholder: `Surname`,
      },
      {
        name: `email`,
        placeholder: `Email`,
      },
    ],
    formSubmitText: `Try your luck`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Try again`,
      `Try your luck again !`,
      `Maybe next time !`,
      `Next time maybe !`,
    ],
  }
];
