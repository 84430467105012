import * as THREE from 'three'
import EventEmitter from './EvEmitter'
import Mats from './Mats'

export default class Shoes extends EventEmitter {
	constructor(ap3, assets) {
		super()
		this.init(ap3)
		this.setMats(assets.mats, assets.texs)
		this.setShoes(assets.glbs)
		this.setAnims(assets.glbs)
	}

	init(ap3) {
		this.ap3 = ap3
		this.hRig1 = new THREE.Object3D()
		this.hRig2 = new THREE.Object3D()
		this.ap3.scene.add(this.hRig1)
		this.hRig1.add(this.hRig2)
		this.fracs = { frac_01: 0 }
		this.mixer = new THREE.AnimationMixer(this.fracs)
	}

	setMats(mats, texs) {
		this.matsShoes = new Mats(mats, texs)
	}

	setShoes(glbs) {
		this.shoes = glbs.shoes.scene
		this.shoes.traverse((o) => {
			if(!o.isMesh) { this[o.name] = o }
			else if(o.name == 'aLacet' || o.name == 'bSemelle_02' || o.name == 'bLigne_01' || o.name == 'aSemelle_01') { o.material = this.matsShoes.mats.blanc }
			else if(o.name == 'aCrampons' || o.name == 'bCrampon') { o.material = this.matsShoes.mats.aCrampon }
			else if(o.name == 'aLanguette_02') { o.material = this.matsShoes.mats.aLanguette_02 }
			else if(o.name == 'aMorceau_01' || o.name == 'bMorceau_01') { o.material = this.matsShoes.mats.aMorceau_01 }
			else if(o.name == 'aMorceau_02') { o.material = this.matsShoes.mats.aMorceau_02 }
			else if(o.name == 'aSemelle_02') { o.material = this.matsShoes.mats.aSemelle_02 }
			else if(o.name == 'aSemelle_03' || o.name == 'bSemelle_03') { o.material = this.matsShoes.mats.aSemelle_03 }
			else if(o.name == 'aSemelle_04') { o.material = this.matsShoes.mats.aSemelle_04 }
			else if(o.name == 'aSemelleIn') { o.material = this.matsShoes.mats.aSemelleIn }
			else if(o.name == 'aTissu_01' || o.name == 'aLanguette_01') { o.material = this.matsShoes.mats.aTissu_01 }
			else if(o.name == 'aTissu_02') { o.material = this.matsShoes.mats.aTissu_02 ; o.material.side = THREE.DoubleSide }
			else if(o.name == 'bCouture') { o.material = this.matsShoes.mats.bCouture }
			else if(o.name == 'bLacet') { o.material = this.matsShoes.mats.bLacet }
			else if(o.name == 'bLanguette_01') { o.material = this.matsShoes.mats.bLanguette_01 }
			else if(o.name == 'bLanguette_02') { o.material = this.matsShoes.mats.bLanguette_02 }
			else if(o.name == 'bLigne_02') { o.material = this.matsShoes.mats.bLigne_02 }
			else if(o.name == 'bMorceau_02') { o.material = this.matsShoes.mats.bMorceau_02 }
			else if(o.name == 'bMorceau_03') { o.material = this.matsShoes.mats.bMorceau_03 }
			else if(o.name == 'bSemelle_01') { o.material = this.matsShoes.mats.bSemelle_01 }
			else if(o.name == 'bSemelleIn') { o.material = this.matsShoes.mats.bSemelleIn }
			else if(o.name == 'bTissu_01') { o.material = this.matsShoes.mats.bTissu_01 }
			else if(o.name == 'bTissu_02') { o.material = this.matsShoes.mats.bTissu_02 }
			else if(o.name == 'bTissu_03') { o.material = this.matsShoes.mats.bTissu_03 }
			else if(o.name == 'bTissuIn') { o.material = this.matsShoes.mats.bTissuIn }
		})
	}

	setAnims(glbs) {
		for(const anim of glbs.shoes.animations) {
			const id = anim.name.slice(-2)
			const times = anim.tracks[0].times
			const values = anim.tracks[0].values
			this['tim_' + id] = []
			for(let i = 0 ; i < times.length ; i++) { this['tim_' + id].push(times[i]) }
			this['val_' + id] = []
			for(let i = 0 ; i < values.length / 3 ; i++) { this['val_' + id].push(values[i*3]) }
			this['dur_' + id] = (this['tim_' + id].length - 1) / 25
			const frac = '.frac_'.concat(id)
			const track = new THREE.NumberKeyframeTrack(frac, this['tim_' + id], this['val_' + id], THREE.InterpolateSmooth)
			this['action_' + id] = this.mixer.clipAction(new THREE.AnimationClip(id, this['dur_' + id], [track]))
			this['action_' + id].loop = THREE.LoopOnce
			this['action_' + id].clampWhenFinished = true
		}
	}
}