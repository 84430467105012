export default [
  {
    name: `greeting_page`,
    content: {
      title: `FELICITACIONES`,
      subtitle: `Tiene la posibilidad de ganar un conjunto  Kalenji`,
      cta: `Continuar`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Gracias por tu participación`,
      subtitle: ``,
      cta: `Volver a la sala de exposición`,
    },
  },
  {
    name: `game_form`,
    title: `LLENE EL FORMULARIO PARA PARTICIPAR`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Nombre`,
      },
      {
        name: `lastname`,
        placeholder: `Apellido`,
      },
      {
        name: `email`,
        placeholder: `Email`,
      },
    ],
    formSubmitText: `Pruebe su suerte`,
  },
  {
    name: `lose_messages`,
    messages: [
      `¿Quizás la próxima vez? `,
  
    ],
  }
];