export default [
  {
    name: `introduction_tutorial`,
    content: `Explore in 360°`,
    cta: `Continue`,
  },
  {
    name: `game_tutorial`,
    title: `LET'S PLAY`,
    content: `Click to find the Jogflow in the clouds for a chance to win a Kalenji outfit`,
    cta: `Continue`,
  },
]