import * as THREE from 'three'

export default class Cam {
	constructor(ap3) {
		this.init(ap3)
	}

	init(ap3) {
		this.ap3 = ap3
		this.cam = new THREE.PerspectiveCamera( 39, this.ap3.sizes.w / this.ap3.sizes.h, 2, 8 )
		this.cam.position.z = 5
	}

	resize() {
		this.cam.aspect = this.ap3.sizes.w / this.ap3.sizes.h
		this.cam.updateProjectionMatrix()
	}
}