
export default [
  {
    name: `main`,
    video: require(`@/assets/360/main.mp4`),
    sphereGlb: require(`@/assets/glbs/sphere.glb`),
    gameImage: require(`@/assets/texs/game.jpg`),
    clouds: [
      { name: `cloud1`, image: require(`@/assets/texs/nuage_01.png`) },
      { name: `cloud2`, image: require(`@/assets/texs/nuage_02.png`) },
      { name: `cloud3`, image: require(`@/assets/texs/nuage_03.png`) },
      { name: `cloud4`, image: require(`@/assets/texs/nuage_04.png`) },
      { name: `cloud5`, image: require(`@/assets/texs/nuage_05.png`) },
      { name: `cloud6`, image: require(`@/assets/texs/nuage_06.png`) },
      { name: `cloud7`, image: require(`@/assets/texs/nuage_07.png`) },
      { name: `cloud8`, image: require(`@/assets/texs/nuage_08.png`) },
      { name: `cloud9`, image: require(`@/assets/texs/nuage_09.png`) },
      { name: `cloud10`, image: require(`@/assets/texs/nuage_10.png`) },
      { name: `cloud11`, image: require(`@/assets/texs/nuage_11.png`) },
      { name: `cloud12`, image: require(`@/assets/texs/nuage_12.png`) },
    ],
  },
];
