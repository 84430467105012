<template>
  <div class="loading-bar">
    <img :src="fullImage" alt="image-empty" />
    <div>{{ progressLabel }} %</div>
    <div class="loading-bar-wrapper" :style="`width: ${progress}%;`">
      <img :src="fullImage" alt="image-full">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: false,
      default: () => 0,
    },
    config: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    emptyImage() {
      return this.getConfigItem(`kalenji_grey_logo`)?.image || ``;
    },
    fullImage() {
      return this.getConfigItem(`kalenji_white_logo`)?.image || ``;
    },
    progressLabel() {
      return Math.ceil(this.progress);
    },
  },

  methods: {
    getConfigItem(name) {
      return this.config.find(item => item.name === name) || null;
    },
  },
}
</script>

<style lang="scss">
.loading-bar {
  width: 50vw;
  max-width: 380px;
  position: relative;

  img {
    width: 50vw;
    max-width: 380px;
  }

  & > img {
    opacity: .3;
  }

  .loading-bar-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: width .4s;
  }
}
</style>
