export default [
  {
    name: `laurel-green`,
    title: `Verde laurel`,
    sizes: [
      { value: 36, price: `34.99€` },
      { value: 37, price: `34.99€` },
      { value: 38, price: `34.99€` },
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
    ],
  },
  {
    name: `black`,
    title: `Negro`,
    sizes: [
      { value: 36, price: `34.99€` },
      { value: 37, price: `34.99€` },
      { value: 38, price: `34.99€` },
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
    ],
  },
  {
    name: `orange`,
    title: `Naranja`,
    sizes: [
      { value: 36, price: `34.99€` },
      { value: 37, price: `34.99€` },
      { value: 38, price: `34.99€` },
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
    ],
  },
  {
    name: `foggy-blue`,
    title: `Gris niebla`,
    sizes: [
      { value: 36, price: `34.99€` },
      { value: 37, price: `34.99€` },
      { value: 38, price: `34.99€` },
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
    ],
  },
];