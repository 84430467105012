export default [
  {
    name: `introduction_tutorial`,
    content: `360°-Ansicht`,
    cta: `Weiter`,
  },
  {
    name: `game_tutorial`,
    title: `LUST AUF EIN SPIEL?`,
    content: `Mit einem Klick und etwas Glück kannst du den JogFlow in den Wolken finden und ein Kalenji-Outfit gewinnen.`,
    cta: `Weiter`,
  },
]
