
export default [
  {
    name: `background`,
    src: require(`@/assets/sounds/background.mp3`),
  },
  {
    name: `interface-click`,
    src: require(`@/assets/sounds/interface_click.mp3`),
  },
  {
    name: `multimedia-click-1`,
    src: require(`@/assets/sounds/multimedia_click_1.mp3`),
  },
  {
    name: `multimedia-click-2`,
    src: require(`@/assets/sounds/multimedia_click_2.mp3`),
  },
  {
    name: `multimedia-click-3`,
    src: require(`@/assets/sounds/multimedia_click_3.mp3`),
  },
  {
    name: `sonar-1`,
    src: require(`@/assets/sounds/sonar_1.mp3`),
  },
  {
    name: `sonar-2`,
    src: require(`@/assets/sounds/sonar_2.mp3`),
  },
  {
    name: `sonar-3`,
    src: require(`@/assets/sounds/sonar_3.mp3`),
  },
];
