import EventEmitter from './EvEmitter'

export default class Sizes extends EventEmitter {
	constructor(ap3) {
		super()
		this.init(ap3)
		this.updtSizes()
	}

	init(ap3) {
		this.container = document.querySelector('.main-scene-container .canvas-container') || document.body
		this.w = 1000
		this.h = 1000
		this.pr = 1
		window.addEventListener('resize', () => {
			this.updtSizes()
			ap3.cam.resize()
			ap3.renderer.resize()
		})
	}

	updtSizes() {
		this.w = this.container.getBoundingClientRect().width
		this.h = this.container.getBoundingClientRect().height
		this.pr = Math.min(window.devicePixelRatio, 2)
	}
}