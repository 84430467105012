export default [
  {
    name: `powdery-beige`,
    title: `Beige poudre`,
    sizes: [
      { value: 36, price: `50€` },
      { value: 37, price: `50€` },
      { value: 38, price: `50€` },
      { value: 39, price: `50€` },
      { value: 40, price: `50€` },
      { value: 41, price: `50€` },
      { value: 42, price: `50€` },
    ],
  },
  {
    name: `khaki`,
    title: `Kaki`,
    sizes: [
      { value: 36, price: `50€` },
      { value: 37, price: `50€` },
      { value: 38, price: `50€` },
      { value: 39, price: `50€` },
      { value: 40, price: `50€` },
      { value: 41, price: `50€` },
      { value: 42, price: `50€` },
    ],
  },
];