
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Tylko 200 g`,
      content: `Aby w pełni wykorzystać każdy krok, Jogflow 500.1 waży tylko 200 gramów!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Przewiewna siateczka`,
      content: `Bardzo przewiewna siateczka umożliwia lepszą oddychalność stopy i sprawia, że biegacz, mając suche stopy, może się w pełni cieszyć każdą chwilą.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop 4 mm`,
      content: `W butach Jogflow 500.1, Kalenji zaproponowało 4-milimetrowy drop, aby zachęcić biegacza do stawiania najpierw przedniej części stopy, dzięki czemu krok jest bardziej naturalny, co ogranicza ryzyko kontuzji.`,
    },
    {
      name: `eva-foam`,
      title: `EVA Foam`,
      content: `Aby zapobiec zbyt głębokiemu zagłębianiu się stopy w bucie podczas biegu, zespoły projektowe stworzyły miękką amortyzację z pianki EVA, która zmniejsza odczuwanie drgań na pięcie.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Sole`,
      content: `Buty Jogflow 500.1 posiadają podeszwę Flex H, zaprojektowaną tak, aby były maksymalnie giętkie. Elastyczne nacięcia na podeszwie reagują na każdy krok, co zwiększa swobodę ruchów stopy. Jednocześnie podeszwa spełnia swoją naturalną rolę amortyzatora.`,
    },
  ]
};