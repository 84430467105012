
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Eco design`,
      content: `Kalenji gebruikt gerecycled materiaal voor het design van de "knitted" bovenkant als ook van de binnen en buitenzool. De bovenkant is 62% eco-designed!`,
    },
    {
      name: `3d-knit`,
      title: `3D Knit`,
      content: `Kalenji heeft een 3D "knit" bovenkant ontworpen die aanvoelt als een "tweede huid" voor wie de schoen aanheeft.`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `De Jogflow 500K.1 van Kalenji heeft een drop van 4 millimeter om de loper uit te nodigen om op de voorvoet te landen. Dit bevordert het natuurlijke looppatroon en verlaagt de kans op blessures.`,
    },
    {
      name: `eva-foam`,
      title: `EVA Schuim`,
      content: `Om te voorkomen dat de voet teveel wegzakt in de zool heeft het design team een zacht EVA schuim ontwikkeld die de landing optimaal dempt bij iedere stap.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Zool`,
      content: `De Jogflow 500K.1 heeft een Flex H zool, die ontworpen is om de schoen zo soepel mogelijk te maken. De inkepingen in de zool zorgen voor een optimale bewegingsvrijheid van de voet en dit zorgt voor een natuurlijke demping.`,
    },
  ]
};