
export default [
  {
    name: `flow_logo`,
    image: require(`@/assets/logos/flow.png`),
  },
  {
    name: `kalenji_logo`,
    image: require(`@/assets/logos/kalenji_flow.png`),
  },
  {
    name: `start_cta`,
    label: ``,
  }
];