export default [
  {
    name: `laurel-green`,
    title: `Laurowa zieleń`,
    sizes: [
      { value: 36, price: `149,99 zł` },
      { value: 37, price: `149,99 zł` },
      { value: 38, price: `149,99 zł` },
      { value: 39, price: `149,99 zł` },
      { value: 40, price: `149,99 zł` },
      { value: 41, price: `149,99 zł` },
      { value: 42, price: `149,99 zł` },
    ],
  },
  {
    name: `black`,
    title: `Czarny`,
    sizes: [
      { value: 36, price: `149,99 zł` },
      { value: 37, price: `149,99 zł` },
      { value: 38, price: `149,99 zł` },
      { value: 39, price: `149,99 zł` },
      { value: 40, price: `149,99 zł` },
      { value: 41, price: `149,99 zł` },
      { value: 42, price: `149,99 zł` },
    ],
  },
  {
    name: `orange`,
    title: `Pomarańczowy`,
    sizes: [
      { value: 36, price: `149,99 zł` },
      { value: 37, price: `149,99 zł` },
      { value: 38, price: `149,99 zł` },
      { value: 39, price: `149,99 zł` },
      { value: 40, price: `149,99 zł` },
      { value: 41, price: `149,99 zł` },
      { value: 42, price: `149,99 zł` },
    ],
  },
  {
    name: `foggy-blue`,
    title: `Szara mgła`,
    sizes: [
      { value: 36, price: `149,99 zł` },
      { value: 37, price: `149,99 zł` },
      { value: 38, price: `149,99 zł` },
      { value: 39, price: `149,99 zł` },
      { value: 40, price: `149,99 zł` },
      { value: 41, price: `149,99 zł` },
      { value: 42, price: `149,99 zł` },
    ],
  },
];