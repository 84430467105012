export default [
  {
    name: `greeting_page`,
    content: {
      title: `HERZLICHEN GLÜCKWUNSCH`,
      subtitle: `Du hast Glück gehabt und ein Kalenji-Outfit gewonnen`,
      cta: `Weiter`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Danke für Ihre Teilnahme`,
      subtitle: ``,
      cta: `Kehre in den Ausstellungsraum zurück`,
    },
  },
  {
    name: `game_form`,
    title: `DU MÖCHTEST TEILNEHMEN? BITTE FÜLLE DAS FORMULAR AUS`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Vorname`,
      },
      {
        name: `lastname`,
        placeholder: `Nachname`,
      },
      {
        name: `email`,
        placeholder: `E-Mail-Adresse`,
      },
    ],
    formSubmitText: `Viel Glück!`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Vielleicht hast du beim nächsten Mal mehr Glück ...`,
    ],
  }
];