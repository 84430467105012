export default [
  {
    name: `introduction_tutorial`,
    content: `Obejrzyj pod kątem 360°`,
    cta: `Kontynuuj`,
  },
  {
    name: `game_tutorial`,
    title: `LET'S PLAY`,
    content: `Kliknij, aby znaleźć Jogflow w chmurze i mieć szansę na wygranie stroju Kalenji`,
    cta: `Kontynuuj`,
  },
]
