import * as THREE from 'three'
import Time from './Time'
import Sizes from './Sizes'
import Cam from './Cam'
import Renderer from './Renderer'
import Pointer from './Pointer'
import AssetsLoader from './AssetsLoader'
import Lighting from './Lighting'
import Shoes from './Shoes'
import Action from './Action'

export default class Ap3 {
	constructor(canvas) {
		this.canvas = canvas
		this.start()
	}

	start() {
		this.scene = 				new THREE.Scene()
		this.time = 				new Time(this)
		this.sizes = 				new Sizes(this)
		this.cam = 					new Cam(this)
		this.renderer = 		new Renderer(this)
		this.pointer = 			new Pointer(this)
		this.assetsLoader = new AssetsLoader()

		this.time.addEvent('render')
	}

	addAssets(assets, handler) { return this.assetsLoader.load(assets, handler) }
	
	setLighting(env) {
		this.lighting = new Lighting(this, env)
	}

	setShoes(shoesAssets) {
		this.shoes = new Shoes(this, shoesAssets)
		this.action = new Action(this)
	}
}