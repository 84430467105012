export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Only 200g`,
      content: `To make the most of every strude, the Jogflow 500.1 weighs only 200 grams!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Ventilated Mesh`,
      content: `The ultra-ventilated mesh allows for better breathability of the foot and invites the runner to enjoy only the moment, well dry.`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `Kalenji offer a 4 millimeter drop to its Jogflow 500.1 to invite the runner to attack the ground with the forefoot and thus help him adopt a more natural stride and limit the risk of injuries.`,
    },
    {
      name: `eva-foam`,
      title: `EVA Foam`,
      content: `To prevent the foot from sinking too far into the sole, the design teams create a soft EVA foam cushioning to reduce the attack on the heel.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Sole`,
      content: `The Jogflow 500.1 benefits from a Flex H sole, designed to keep the shoe as flexible as possible. These flex notches on the sole allow for each stride and provide freedom of movements for the foot while ensuring its natural role as a shock absorber.`,
    },
  ]
};
