
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Sólo 200g`,
      content: `Para sacarle provecho a cada paso, ¡las  Jogflow 500.1 pesan sólo 200 gramos!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Malla ventilada`,
      content: `La malla ultra ventilada brinda una mejor transpirabilidad al pie e invita al runner a disfrutar el momento manteniéndolo seco.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop de 4mm`,
      content: `Kalenji presenta un drop de 4 milímetros en sus Jogflow 500.1 invitando al runner a enfrentar el suelo con el antepié, adoptando un paso más natural y limitando el riesgo de lesiones.
      `,
    },
    {
      name: `eva-foam`,
      title: `Espuma EVA`,
      content: `ara evitar que el pie se sumerja demasiado en la plantilla, el equipo de diseñadores ha creado una suela de espuma EVA acolchada que reduce el impacto en el talón.`,
    },
    {
      name: `flex-h-sole`,
      title: `Suela Flex H `,
      content: `La Jogflow 500.1 presenta una suela Flex H, diseñada para que la zapatilla sea lo más flexible posible. Las muescas flexibles en la suela facilitan el andar y brindan libertad de movimiento al pie, asegurando su rol natural, la amortiguación.`,
    },
  ]
};