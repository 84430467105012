<template>
  <div
    class="kalenji-tuto"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    @click="emitClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  methods: {
    emitClick() {
      this.$emit(`click`);
    },
  },
};
</script>

<style lang="scss" scoped>
.kalenji-tuto {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.518);
  color: #FFF;
  font-size: 16px;
  padding: 30px 15px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  h2 {
    font-size: 25px;
    font-weight: 700;
  }

  .tuto-content {
    font-weight: 400;
  }

  .kalenji-button {
    width: 60%;
    max-width: 250px;
  }

  &:not(.mobile) {

    h2 {
      font-size: 32px;
    }

    .tuto-content {
      font-size: 25px;
    }
  }
}
</style>
