
export default [
  {
    name: `intro-video`,
  },
  {
    name: `breathability`,
  },
  {
    name: `comfort`,
  },
  {
    name: `injuries-prevention`,
  },
  {
    name: `flow-500k-1`,
  },
  {
    name: `lightness`,
  },
  {
    name: `technicity-shoes`,
  },
];
