import * as THREE from 'three'
import Mats from './Mats'

export default class Sphere {
	constructor(ap3, assets) {
		this.init(ap3)
		this.setMats(assets.mats, assets.texs)
		this.setSphere(assets.glbs)
		this.ap3.time.addEvent('sphere')
	}

	init(ap3) {
		this.ap3 = ap3
		this.params = {
			paralHorFactor: 2.5,
			paralVerFactor: 2
		}
		this.video = ap3.sphereVideoElement || document.getElementById('main-scene-video')
		this.matVid = new THREE.MeshBasicMaterial({ map: new THREE.VideoTexture(this.video) })
		this.video.play()
	}

	setMats(mats, texs) {
		this.matsSphere = new Mats(mats, texs)
	}

	setSphere(glbs) {
		this.sphere = glbs.sphere.scene
		this.ap3.scene.add(this.sphere)
		this.sphere.traverse((o) => { if(!o.isMesh) { this[o.name] = o } else {
			if(o.name === 'sphere') {
				this.fond = o
				o.material = this.matVid
			} else if(o.name.includes('zone')) {
				this.ap3.ray.castListHome.push(o)
				o.visible = false
			} else if(o.name == 'gameWin') {
				this.ap3.ray.castListGame.push(o)
				o.visible = false
			} else if(o.name.includes('nuage')) {
				this[o.name] = o
				o.renderOrder =  parseInt(o.name.slice(-2))
				o.material = this.matsSphere.mats[o.name]
			}
		}})
		this.nuage_05.visible = false
		this.nuage_06.visible = false
		this.nuage_07.visible = false
		this.nuage_08.visible = false
		this.nuage_09.visible = false
		this.nuage_10.visible = false
		this.nuage_11.visible = false
		this.nuage_12.visible = false
	}

	startGame() {
		this.fond.material = this.matsSphere.mats.game
		this.ap3.ray.castList = this.ap3.ray.castListGame
		this.initGameSphereRot()

	}

	stopGame() {
		this.fond.material = this.matVid
		this.ap3.ray.castList = this.ap3.ray.castListHome
		this.initMainSphereRot()
	}

	initMainSphereRot() {
		this.initRot(this.ap3.cam.params.iRotLon)
		this.nuage_01.visible = true
		this.nuage_02.visible = true
		this.nuage_03.visible = true
		this.nuage_04.visible = true
		this.nuage_05.visible = false
		this.nuage_06.visible = false
		this.nuage_07.visible = false
		this.nuage_08.visible = false
		this.nuage_09.visible = false
		this.nuage_10.visible = false
		this.nuage_11.visible = false
		this.nuage_12.visible = false
	}

	initGameSphereRot() {
		this.initRot(-20)
		this.nuage_01.visible = false
		this.nuage_02.visible = false
		this.nuage_03.visible = false
		this.nuage_04.visible = false
		this.nuage_05.visible = true
		this.nuage_06.visible = true
		this.nuage_07.visible = true
		this.nuage_08.visible = true
		this.nuage_09.visible = true
		this.nuage_10.visible = true
		this.nuage_11.visible = true
		this.nuage_12.visible = true
	}

	initRot(offset) {
		this.ap3.cam.target.x = - this.ap3.cam.hGyr0.rotation.y + offset * Math.PI/180
		this.ap3.cam.hCam0.rotation.y = this.ap3.cam.target.x
		this.ap3.cam.target.y = - this.ap3.cam.hGyr1.rotation.x
		this.ap3.cam.hCam1.rotation.x = this.ap3.cam.target.y
	}

	tick() {
		const ver = Math.cos(this.ap3.cam.hCam1.rotation.x + this.ap3.cam.hGyr1.rotation.x + Math.PI/2)
		const hor = (this.ap3.cam.hCam0.rotation.y + this.ap3.cam.hGyr0.rotation.y + Math.PI/2 + Math.PI*101)%(Math.PI*2) - Math.PI

		this.hParaLon_01.rotation.y = - Math.sin(hor) * 0.1 * this.params.paralHorFactor
		this.hParaLon_02.rotation.y = ( Math.sin(this.hParaLon0_02.rotation.y) - Math.sin(hor) ) * 0.1 * this.params.paralHorFactor
		this.hParaLon_03.rotation.y = ( - Math.sin(Math.PI - this.hParaLon0_03.rotation.y) + Math.sin(hor) ) * 0.1 * this.params.paralHorFactor
		this.hParaLon_04.rotation.y = ( - Math.sin(Math.PI - this.hParaLon0_04.rotation.y) + Math.sin(hor) ) * 0.1 * this.params.paralHorFactor
		this.hParaLon_05.rotation.y = Math.sin(hor + this.hParaLon0_05.rotation.y - Math.PI/2) * 0.1 * this.params.paralHorFactor * 1
		this.hParaLon_06.rotation.y = Math.sin(hor + this.hParaLon0_06.rotation.y) * 0.1 * this.params.paralHorFactor * 1.2
		this.hParaLon_07.rotation.y = Math.sin(hor + this.hParaLon0_07.rotation.y) * 0.1 * this.params.paralHorFactor * 1.4
		this.hParaLon_08.rotation.y = Math.sin(hor + this.hParaLon0_08.rotation.y) * 0.1 * this.params.paralHorFactor * 1.6
		this.hParaLon_09.rotation.y = Math.sin(hor + this.hParaLon0_09.rotation.y) * 0.1 * this.params.paralHorFactor * 1.8
		this.hParaLon_10.rotation.y = Math.sin(hor + this.hParaLon0_10.rotation.y - Math.PI * 1) * 0.1 * this.params.paralHorFactor * 2
		this.hParaLon_11.rotation.y = Math.sin(hor + this.hParaLon0_11.rotation.y) * 0.1 * this.params.paralHorFactor * 2.2
		this.hParaLon_12.rotation.y = Math.sin(hor + this.hParaLon0_12.rotation.y) * 0.1 * this.params.paralHorFactor * 2.4

		this.hParaAzi_01.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_02.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_03.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_04.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_05.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_06.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_07.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_08.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_09.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_10.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_11.rotation.z = ver * 0.1 * this.params.paralVerFactor
		this.hParaAzi_12.rotation.z = ver * 0.1 * this.params.paralVerFactor
	}
}