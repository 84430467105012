import * as THREE from 'three'

export default class Lighting {
	constructor(ap3, env) {
		this.init(ap3)
		this.setEnv(env)
		this.setSun()
	}

	init(ap3) {
		this.ap3 = ap3
	}
	
	setEnv(env) {
		this.ap3.scene.environment = env
	}

	setSun() {
		const sun = new THREE.DirectionalLight(0xffffff, 0.75)
		this.ap3.scene.add(sun)
		sun.position.set( 5, 5, 5 )
	}
}