<template>
  <div
    class="product-page-wrapper"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="product-page-header">
      <button
        @click="close"
      >
        <img src="@/assets/icons/arrow_left_black.svg" alt="go-back" />
      </button>
      <img src="@/assets/logos/kalenji_grey.png" alt="kalenji-logo">
    </div>
    <div class="product-page-categories-model" v-if="!$breakpoints.isMobile">
      <label :class="{'selected-label': currentShoe === `500K`}" for="currentShoe-500K">
        <input type="radio" name="currentShoe" id="currentShoe-500K" value="500K" v-model="currentShoe"/>
        500K.1
      </label>
      <label :class="{'selected-label': currentShoe === `500`}" for="currentShoe-500">
        <input type="radio" name="currentShoe" id="currentShoe-500" value="500" v-model="currentShoe"/>
        500.1
      </label>
    </div>
    <div class="product-page-content-wrapper">
      <div class="product-page-carousel-wrapper">
        <carousel
          :per-page="1"
          :navigationEnabled="true"
          :navigationNextLabel="navRight"
          :navigationPrevLabel="navLeft"
          :paginationEnabled="true"
          paginationActiveColor="#595959"
          paginationColor="#fff"
          :paginationPadding="10"
          :paginationSize="10"
          paginationPosition="bottom-overlay"
        >
          <slide
            v-for="(img, idx) in currProduct.carousel"
            :key="idx"
          >
          <img class="product-page-carousel-img" :src="img" :alt="`carousel-slide-${idx}`" />
          </slide>
        </carousel>
      </div>
      <div class="product-page-product-info">
        <div class="product-page-categories-wrapper">
          <div class="product-page-categories-gender">
            <label :class="{'selected-label': currentGender === `woman`}" for="currentGender-w">
              <input type="radio" name="currentGender" id="currentGender-w" value="woman" v-model="currentGender"/>
              <span v-show="!$breakpoints.isMobile">
                {{ common.genderWomen }}
              </span>
              <img
                src="@/assets/icons/female_black.svg"
                alt="male-icon-black"
                v-show="currentGender !== `woman` && $breakpoints.isMobile"
              />
              <img
                src="@/assets/icons/female_white.svg"
                alt="male-icon-black"
                v-show="currentGender === `woman` && $breakpoints.isMobile"
              />
            </label>
            <label :class="{'selected-label': currentGender === `man`}" for="currentGender-m">
              <input type="radio" name="currentGender" id="currentGender-m" value="man" v-model="currentGender"/>
              <span v-show="!$breakpoints.isMobile">
                {{ common.genderMen }}
              </span>
              <img
                src="@/assets/icons/male_black.svg"
                alt="male-icon-black"
                v-show="currentGender !== `man` && $breakpoints.isMobile"
              />
              <img
                src="@/assets/icons/male_white.svg"
                alt="male-icon-black"
                v-show="currentGender === `man` && $breakpoints.isMobile"
              />
            </label>
          </div>
          <div class="product-page-categories-model" v-if="$breakpoints.isMobile">
            <label :class="{'selected-label': currentShoe === `500K`}" for="currentShoe-500K">
              <input type="radio" name="currentShoe" id="currentShoe-500K" value="500K" v-model="currentShoe"/>
              500K.1
            </label>
            <label :class="{'selected-label': currentShoe === `500`}" for="currentShoe-500">
              <input type="radio" name="currentShoe" id="currentShoe-500" value="500" v-model="currentShoe"/>
              500.1
            </label>
          </div>
        </div>
        <div class="product-page-shoe-info">
          <h3>JogFlow {{ currentShoe }}.1</h3>
          <h4>{{ currProduct.title }}</h4>
          <h3>{{ currentPrice }}</h3>
        </div>
        <div class="product-page-shoe-colors">
          <div class="product-page-shoe-colors-title">{{ common.colorSectionTitle }}</div>
          <div class="product-page-shoe-colors-list">
            <label
              v-for="(color) in currModel"
              :key="`${currentShoe}-${currentGender}-${color.name}`"
              :for="`currentColor-${currentShoe}-${currentGender}-${color.name}`"
              :class="{
                'color-selected': color.name === currentColor,
              }"
            >
              <input type="radio" name="currentColor" :id="`currentColor-${currentShoe}-${currentGender}-${color.name}`" :value="color.name" @click="playMultimediaSound" v-model="currentColor"/>
              <img
                :src="color.icon"
                :alt="`${currentShoe}-${currentGender}-${color.name}`"
              />
            </label>
          </div>
        </div>
        <div class="product-page-shoe-sizes">
          <label
            v-for="(size) in currProduct.sizes"
            :key="`${currentShoe}-${currentGender}-${currentColor}-${size.value}`"
            :for="`currentSize-${currentShoe}-${currentGender}-${currentColor}-${size.value}`"
            :class="{
              'size-selected': size.value === currentSize,
            }"
          >
            <input type="radio" name="currentSize" :id="`currentSize-${currentShoe}-${currentGender}-${currentColor}-${size.value}`" :value="size.value" v-model="currentSize"/>
            {{ size.value }}
          </label>
        </div>
        <form ref="buyForm" :action="addToCartUrl" @submit="playInterfaceSound" target="_blank" method="POST">
          <div class="product-page-buy-cta" @click="submitForm">
            <span>{{ common.buyCTA }}</span>
            <img :src="common.buyCTAIcon" :alt="`${common.buyCTA} icon`">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

const KALENJI_LOCALE_DOMAIN_MAP = {
    'en_US': 'co.uk',
    'en_GB': 'co.uk',
    'fr_FR': 'fr',
    'es_ES': 'es/es',
    'it_IT': 'it',
    'pl_PL': 'pl',
    'de_DE': 'de',
    'nl_NL': 'nl',
    'ch_CN': 'com.cn/zh',
    'ch_TW': 'com.cn/zh',
};

export default {
  components: {
    Carousel,
    Slide
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    productsConfig: {
      type: Object,
      required: true,
    },
    lang: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data: () => ({
    currentShoe: `500K`,
    currentGender: `man`,
    currentColor: `black`,
    currentSize: 39,
    lastTrackedItem: null,
  }),

  computed: {
    common() {
      return this.productsConfig.common;
    },
    currModel() {
      return this.productsConfig[this.currentShoe][this.currentGender];
    },
    currProduct() {
      return this.productsConfig[this.currentShoe][this.currentGender].find(shoe => shoe.name === this.currentColor);
    },
    currentPrice() {
      return this.currProduct.sizes.find(s => s.value === this.currentSize)?.price;
    },
    navRight() {
      const rightChevron = require('@/assets/icons/chevron_right_white.svg');
      return `<img class="carousel-arrow" src="${rightChevron}" alt="next-slide"/>`
    },
    navLeft() {
      const leftChevron = require('@/assets/icons/chevron_left_white.svg');
      return `<img class="carousel-arrow" src="${leftChevron}" alt="next-slide"/>`
    },
    addToCartUrl() {
      const country = KALENJI_LOCALE_DOMAIN_MAP[this.lang.key];
      const sku = this.currProduct.sizes.find(s => s.value === this.currentSize)?.sku;

      const kalenjiParams = {
          "externalWebsite": "RealityXR",
          "items": [{
              "id": sku,
              "quantity": 1,
          }],
      };
      return `https://www.decathlon.${country}/externalBasket?basket=${encodeURIComponent(JSON.stringify(kalenjiParams))}`;
    },
  },

  methods: {
    close() {
      this.$emit('close-product-page');
    },
    playInterfaceSound() {
      this.$emit(`play-sfx`, `interface`);
    },
    playMultimediaSound() {
      this.$emit(`play-sfx`, `multimedia`);
    },
    trackSelectedItem() {
      const gender = this.currentGender === `man` ? `men` : `women`;
      const shoe = this.currentShoe === `500` ? `500.1` : `500K`;
      const color = this.currProduct.colorKey;
      const trackedItem = `${gender}_${shoe}_${color}`;
      if (trackedItem !== this.lastTrackedItem) {
        this.$gtagEvent(trackedItem);
      }
      this.lastTrackedItem = trackedItem;
    },
    submitForm() {
      this.$gtagEvent(`buy`);
      this.$refs.buyForm.submit();
    },
  },

  watch: {
    currentShoe() {
      this.currentColor = this.productsConfig[this.currentShoe][this.currentGender][0].name;
      this.currentSize = this.currProduct.sizes[0].value;
      this.trackSelectedItem();
    },
    currentGender() {
      this.currentColor = this.productsConfig[this.currentShoe][this.currentGender][0].name;
      this.currentSize = this.currProduct.sizes[0].value;
      this.trackSelectedItem();
    },
    currentColor() {
      this.trackSelectedItem();
    },
    visible(visible) {
      if (visible) {
        this.trackSelectedItem();
      } else {
        this.lastTrackedItem = null;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.product-page-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  [type=radio] {
    position: absolute;
    opacity: 0;
  }

  .product-page-header {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 25%;
      max-width: 300px;
    }

    button {
      position: absolute;
      top: 50%;
      left: 20px;
      border: none;
      background: none;
      margin-top: -15px;

      img {
        width: 25px;
      }
    }
  }
  .product-page-content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .product-page-product-info {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  .product-page-carousel-wrapper {
    width: 100%;

    .product-page-carousel-img {
      width: 100%;
    }
  }

  .product-page-categories-gender,
  .product-page-categories-model {
    label {
      padding: 3px 0;
      border: 1px solid #2c2e2f;
      border-radius: 5px;
      color: #2c2e2f;

      &:first-child {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.selected-label {
        color: #fff;
        background: #2c2e2f;
      }
    }
  }

  .product-page-categories-model,
  .product-page-categories-gender {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    height: 28px;

    label {
      padding: 2px 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .product-page-categories-wrapper {
    width: 100%;
    padding: 0 20px;
    flex-grow: 1;
    max-height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-page-categories-gender {
      label {
        img {
          height: 13px;
        }
      }
    }

    .product-page-categories-model {
      font-size: 12px;
    }
  }

  .product-page-shoe-info {
    padding: 0 20px;
    width: 100%;
    text-align: left;

    h3 {
      font-size: 25px;
      font-weight: 600;
      margin: 0;

      &:last-child {
        margin: 15px 0;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      font-style: italic;
      margin: 0;
    }
  }

  .product-page-shoe-colors {
    margin-top: auto;
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .product-page-shoe-colors-title {
      width: 100%;
      text-align: left;
      font-size: 12px;
      font-weight: 600;
    }

    .product-page-shoe-colors-list {
      padding: 10px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 80px;

      label {
        width: 22%;
        display: inline-block;
        margin: 0 5px;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          outline: 2px solid transparent;
          outline-offset: -2px;
          transition: outline-color .2s;
        }

        &.color-selected {
          img {
            outline-color: #2c2e2f;
          }
        }
      }
    }
  }

  .product-page-shoe-sizes {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    label {
      margin: 0 2px;
      padding: 3px 5px;
      color: #2c2e2f;
      font-weight: 600;
      font-size: 14px;
      border: 1px solid #bebebe;
      border-radius: 5px;
      background-color: transparent;
      transition: border-color .2s, background-color .2s;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &.size-selected {
        border-color: #2c2e2f;
        background-color: #c8c8c8;
      }
    }
  }

  form {
    width: 100%;
    .product-page-buy-cta {
      width: 100%;
      padding: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 300;
      color: #fff;
      background: #2c2e2f;
      border: none;
      -webkit-appearance: none;
      margin: 0;
      border-radius: 0;

      img {
        height: 20px;
        margin-left: 10px;
      }
    }
  }

  &.mobile {
    .product-page-content-wrapper {
      .product-page-product-info {
        align-self: stretch;
      }
    }
  }

  &:not(.mobile) {
    padding: calc(2.5% + 20px) calc(2.5% + 25px);
    background-color: transparent;
    background-image: linear-gradient(to right, #fff, #fff);
    background-repeat: no-repeat;
    background-size: 95% 95%;
    background-position: center;

    label,
    input,
    button {
      cursor: pointer;
    }

    .product-page-header {
      margin: 10px 0;

      img {
        max-width: 180px;
      }
    }

    .product-page-categories-model {
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 220px;
    }

    .product-page-content-wrapper {
      flex-grow: 1;
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .product-page-carousel-wrapper {
        width: 55%;
      }

      .product-page-product-info {
        width: 35%;
        flex-grow: 0;

        .product-page-categories-wrapper {
          margin-bottom: 20px;
          padding: 0;
        }

        .product-page-shoe-info {
          padding: 0;

          h3 {
            font-size: 30px;
            font-weight: 600;
          }
        }

        .product-page-shoe-colors {
          padding: 0;

          .product-page-shoe-colors-list {
            label {
              width: 15%;
            }
          }
        }

        .product-page-shoe-sizes {
          padding: 20px 0;
        }

        form {
          display: flex;

          .product-page-buy-cta {
            width: 100%;
            max-width: 380px;
            padding: 0.5em 1.5em;
          }
        }
      }
    }
  }
}

</style>

<style lang="scss">

.product-page-wrapper {
  &.mobile {
    .VueCarousel {
      width: 100vw;

      .VueCarousel-wrapper,
      .VueCarousel-inner {
        width: 100%;
      }
    }
  }

  &:not(.mobile) {
    .VueCarousel-slide {
      cursor: grab;
      width: 100%;
    }
  }
}

.VueCarousel-dot {
  margin-bottom: 5px;

  &:focus {
    outline: none !important;
  }
}

.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  transform: translateY(-50%) !important;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none !important;
  }

  img {
    height: 16px;
  }
}

.product-page-wrapper:not(.mobile) {
  .VueCarousel-navigation-next,
  .VueCarousel-navigation-prev {
    img {
      height: 32px;
      filter: brightness(0.9);
    }
  }

  .VueCarousel-navigation-prev {
    transform: translate(-150%, -50%) !important;
  }

  .VueCarousel-navigation-next {
    transform: translate(150%, -50%) !important;
  }

}
</style>