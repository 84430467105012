
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Seulement 200 g`,
      content: `Pour tirer le maximum de chaque foulée, la Jogflow 500.1 ne pèse que 200 grammes!`,
    },
    {
      name: `ventilated-mesh`,
      title: ` Mesh aéré`,
      content: `Le mesh ultra-aéré garantit une meilleure respirabilité du pied et permet aux coureurs de profiter du moment présent bien au sec.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop de 4mm`,
      content: `La Jogflow 500.1 de Kalenji est dotée d'un drop de 4 millimètres pour inciter les coureurs à attaquer le sol avec l'avant du pied et par conséquent les aider à adopter une foulée plus naturelle et limiter le risque de blessure.`,
    },
    {
      name: `eva-foam`,
      title: `Mousse EVA`,
      content: `Pour empêcher le pied de s'enfoncer trop  dans la semelle, les équipes de conception ont créé un amorti grâce à une mousse souple en EVA qui réduit l'impact au niveau du talon.`,
    },
    {
      name: `flex-h-sole`,
      title: `Semelle Flex H`,
      content: `La Jogflow 500.1 est dotée d'une semelle Flex H conçue pour garantir une flexibilité maximale de la chaussure. Les encoches de flexion pratiquées sur la semelle procurent une véritable liberté de mouvement au pied tout en facilitant l'absorption des chocs par la semelle, sa fonction première.`,
    },
  ]
};