export default [
  {
    name: `powdery-beige`,
    title: `Powdery beige`,
    sizes: [
      { value: 36, price: `£44.99` },
      { value: 37, price: `£44.99` },
      { value: 38, price: `£44.99` },
      { value: 39, price: `£44.99` },
      { value: 40, price: `£44.99` },
      { value: 41, price: `£44.99` },
      { value: 42, price: `£44.99` },
    ],
  },
  {
    name: `khaki`,
    title: `Khaki`,
    sizes: [
      { value: 36, price: `£44.99` },
      { value: 37, price: `£44.99` },
      { value: 38, price: `£44.99` },
      { value: 39, price: `£44.99` },
      { value: 40, price: `£44.99` },
      { value: 41, price: `£44.99` },
      { value: 42, price: `£44.99` },
    ],
  },
];