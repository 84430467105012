export default [
  {
    name: `introduction_tutorial`,
    content: `Ontdek in 360°`,
    cta: `Doorgaan`,
  },
  {
    name: `game_tutorial`,
    title: `LET'S PLAY`,
    content: `Klik om de Jogflow in de wolken te vinden en maak kans om een Kalenji outfit te winnen.`,
    cta: `Doorgaan`,
  },
]
