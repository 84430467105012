
export default [
  {
    name: `background_image`,
    image: require(`@/assets/background/background_mobile.jpg`)
  },
  {
    name: `background_elements`,
    elems: [
      {
        name: `cloud_one`,
        type: `image`,
        context: [`default`, `lang`],
        path: require(`@/assets/splashscreen_loading/cloud_1.png`),
        styles: {
          top: `-7.5%`,
          left: `-15%`,
          width: `55%`,
          'min-width': `820px`,
        },
      },
      {
        name: `cloud_two`,
        type: `image`,
        context: [`default`, `lang`],
        path: require(`@/assets/splashscreen_loading/cloud_2.png`),
        styles: {
          width: `55%`,
          height: `80vh`,
          right: `-8%`,
          top: `-15%`,
          'min-width': `820px`,
        },
      },
      {
        name: `cloud_three`,
        type: `image`,
        context: [`default`, `lang`],
        path: require(`@/assets/splashscreen_loading/cloud_3.png`),
        styles: {
          height: `850px`,
          width: `1150px`,
          left: `15%`,
          bottom: `-27%`,
          transform: `translate(-15%, 0)`,
        },
      },
      {
        name: `person`,
        type: `image`,
        context: [`default`],
        path: require(`@/assets/splashscreen_loading/person.png`),
        styles: {
          'z-index': `10`,
          right: `7%`,
          bottom: `-25%`,
          height: `100vh`,
        },
      },
      {
        name: `neon`,
        type: `image`,
        context: [`default`, `lang`],
        path: require(`@/assets/splashscreen_loading/neon_cube.png`),
        styles: {
          'z-index': `-1`,
          left: `calc(-5% - 20vh)`,
          top: `3%`,
          width: `calc(100vh)`,
          transform: `rotate(53deg)`,
        },
      },
    ]
  },
];