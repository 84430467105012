export default [
  {
    name: `powdery-beige`,
    title: `Beige polvere`,
    sizes: [
      { value: 36, price: `49,99€` },
      { value: 37, price: `49,99€` },
      { value: 38, price: `49,99€` },
      { value: 39, price: `49,99€` },
      { value: 40, price: `49,99€` },
      { value: 41, price: `49,99€` },
      { value: 42, price: `49,99€` },
    ],
  },
  {
    name: `khaki`,
    title: `Grigio fuliggine`,
    sizes: [
      { value: 36, price: `49,99€` },
      { value: 37, price: `49,99€` },
      { value: 38, price: `49,99€` },
      { value: 39, price: `49,99€` },
      { value: 40, price: `49,99€` },
      { value: 41, price: `49,99€` },
      { value: 42, price: `49,99€` },
    ],
  },
];