export default [
  {
    name: `greeting_page`,
    content: {
      title: `GEFELICITEERD`,
      subtitle: `Je maakt kans om een Kalenji outfit te winnen!`,
      cta: `Doorgaan`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Bedankt voor uw deelname`,
      subtitle: ``,
      cta: `Keer terug naar de showroom`,
    },
  },
  {
    name: `game_form`,
    title: `WYPEŁNIJ FORMULARZ UCZESTNICTWA`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Naam`,
      },
      {
        name: `lastname`,
        placeholder: `Achternaam`,
      },
      {
        name: `email`,
        placeholder: `Email`,
      },
    ],
    formSubmitText: `Probeer je geluk`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Może następnym razem?`,
      
    ],
  }
];