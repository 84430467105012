export default [
  {
    name: `introduction_tutorial`,
    content: `360度探索`,
    cta: `继续`,
  },
  {
    name: `game_tutorial`,
    title: `一起游戏!`,
    content: `点击并找到藏在云里的Jogflow跑鞋，获得赢取Kalenji套装的机会`,
    cta: `Continue`,
  },
]
