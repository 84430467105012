export default [
  {
    name: `greeting_page`,
    content: {
      title: `CONGRATULAZIONI`,
      subtitle: `Ha la possibilità di vincere un equipaggiamento da sport Kalenji`,
      cta: `Continuare`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Grazie per la tua partecipazione`,
      subtitle: ``,
      cta: `Ritorno in showroom`,
    },
  },
  {
    name: `game_form`,
    title: `COMPILI IL FORMULARIO PER PARTECIPARE`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Nome`,
      },
      {
        name: `lastname`,
        placeholder: `Cognome`,
      },
      {
        name: `email`,
        placeholder: `E-mail`,
      },
    ],
    formSubmitText: `Tenti la fortuna`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Meglio la prossima volta?`,
     
    ],
  }
];