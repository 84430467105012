<template>
  <div
    class="kalenji-scene"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <canvas id="spaces-scene" class="scene-container"></canvas>
    <button class="close-button" @click="closeGame" v-show="isInGame">
      <img src="@/assets/icons/arrow_left_white.svg" alt="go-back" />
    </button>
  </div>
</template>

<script>
import Spaces from '@/Spaces';

Number.prototype.map = function (in_min, in_max, out_min, out_max) {
  return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

let spacesApp;

export default {
  components: {
  },

  props: {
    scene: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    doTick: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hotspotsVisible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    muted: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    render: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    gyroEnabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gyroDatas: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isInGame: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    dialog: false,
    isOpeningDialog: false,
    isAnimateEnabled: false,
    isGoingToScene: false,
    isMouseDown: false,
    isUserInteracting: false,
    currentSceneI: 0,
  }),

  computed: {
    dialogHotspots() {
      return this.scene.dialogHotspots;
    },
    hubHotspots() {
      return this.scene.hubHotspots;
    },
    sceneHotspots() {
      return this.scene.sceneHotspots;
    },
    sceneContainer() {
      return document.getElementById(`spaces-scene`);
    },
  },

  methods: {
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    mainSphereZoneClicked(zone) {
      if (this.isInGame) { return; }

      this.playSoundEffect();

      switch (zone) {
        case 1: this.openShoe(); break;
        case 2: this.openVideo(`technicity-shoes`); break;
        case 3: this.openVideo(`injuries-prevention`); break;
        case 4: this.openVideo(`flow-500k-1`); break;
        case 5: this.openVideo(`breathability`); break;
        case 6: this.openVideo(`comfort`); break;
        case 7: this.openVideo(`lightness`); break;
        case 8: this.openGame(); break;
        default: break;
      }
    },
    gameSphereZoneClicked(won = false, args = null) {
      if (!this.isInGame) { return; }

      if (won) {
        this.$emit(`open-game-modal`);
      } else {
        if (args) {
          const coords = {
            x: args.x.map(-1, 1, 0, 100),
            y: args.y.map(-1, 1, 100, 0),
          }

          this.$emit(`lose-game`, coords);
        }
      }
    },
    playSoundEffect() {
      this.$emit(`play-sfx`, `sphere-sfx`);
    },
    openVideo(videoName) {
      this.$emit(`open-video`, videoName);
    },
    openShoe() {
      this.$emit(`open-shoe`);
    },
    openGame() {
      this.$emit(`open-game`);
    },
    closeGame() {
      this.$emit(`close-game`);
    },
  },

  async mounted() {
    const videoElement = document.getElementById(`video-scene-${this.scene.uuid}`);
    spacesApp = new Spaces(this.sceneContainer, {
      sphereVideoElement: videoElement,
    });
    const sphereHandler = {};
		await spacesApp.addAssets([
      // { name: 'sphere', type: 'glb', url: require(`@/assets/glbs/sphere.glb`) },
      // { name: 'game', type: 'tex', url: require(`@/assets/texs/game.jpg`) },
      { name: 'sphere', type: 'glb', url: this.scene.sphereGlb },
      { name: 'game', type: 'tex', url: this.scene.gameImage },
      { name: 'nuage_01', type: 'tex', url: this.scene.clouds[0].image, flip: true },
      { name: 'nuage_02', type: 'tex', url: this.scene.clouds[1].image, flip: true },
      { name: 'nuage_03', type: 'tex', url: this.scene.clouds[2].image, flip: true },
      { name: 'nuage_04', type: 'tex', url: this.scene.clouds[3].image, flip: true },
      { name: 'nuage_05', type: 'tex', url: this.scene.clouds[4].image, flip: true },
      { name: 'nuage_06', type: 'tex', url: this.scene.clouds[5].image, flip: true },
      { name: 'nuage_07', type: 'tex', url: this.scene.clouds[6].image, flip: true },
      { name: 'nuage_08', type: 'tex', url: this.scene.clouds[7].image, flip: true },
      { name: 'nuage_09', type: 'tex', url: this.scene.clouds[8].image, flip: true },
      { name: 'nuage_10', type: 'tex', url: this.scene.clouds[9].image, flip: true },
      { name: 'nuage_11', type: 'tex', url: this.scene.clouds[10].image, flip: true },
      { name: 'nuage_12', type: 'tex', url: this.scene.clouds[11].image, flip: true },
			{ name: 'game', type:'matBas', map: 'game' },
			{ name: 'nuage_01', type:'matBas', map: 'nuage_01', transparent: true },
			{ name: 'nuage_02', type:'matBas', map: 'nuage_02', transparent: true },
			{ name: 'nuage_03', type:'matBas', map: 'nuage_03', transparent: true },
			{ name: 'nuage_04', type:'matBas', map: 'nuage_04', transparent: true },
			{ name: 'nuage_05', type:'matBas', map: 'nuage_05', transparent: true },
			{ name: 'nuage_06', type:'matBas', map: 'nuage_06', transparent: true },
			{ name: 'nuage_07', type:'matBas', map: 'nuage_07', transparent: true },
			{ name: 'nuage_08', type:'matBas', map: 'nuage_08', transparent: true },
			{ name: 'nuage_09', type:'matBas', map: 'nuage_09', transparent: true },
			{ name: 'nuage_10', type:'matBas', map: 'nuage_10', transparent: true },
			{ name: 'nuage_11', type:'matBas', map: 'nuage_11', transparent: true },
			{ name: 'nuage_12', type:'matBas', map: 'nuage_12', transparent: true },
    ], sphereHandler)
		spacesApp.setSphere(sphereHandler)
    if (this.gyroEnabled) {
      spacesApp.gyro.enableGyro();
    }
    spacesApp.ray.on(`zone_01`, () => { this.mainSphereZoneClicked(1); });
    spacesApp.ray.on(`zone_02`, () => { this.mainSphereZoneClicked(2); });
    spacesApp.ray.on(`zone_03`, () => { this.mainSphereZoneClicked(3); });
    spacesApp.ray.on(`zone_04`, () => { this.mainSphereZoneClicked(4); });
    spacesApp.ray.on(`zone_05`, () => { this.mainSphereZoneClicked(5); });
    spacesApp.ray.on(`zone_06`, () => { this.mainSphereZoneClicked(6); });
    spacesApp.ray.on(`zone_07`, () => { this.mainSphereZoneClicked(7); });
    spacesApp.ray.on(`zone_08`, () => { this.mainSphereZoneClicked(8); });
    spacesApp.ray.on(`gameWin`, () => { this.gameSphereZoneClicked(true); });
    spacesApp.ray.on(`gameLose`, (args) => { this.gameSphereZoneClicked(false, args); });
    this.$emit(`scene-mounted`);
  },

  watch: {
    doTick(ticking) {
      if (ticking) {
        spacesApp.time.startTicking();
      } else {
        spacesApp.time.stopTicking();
      }
    },
    visible(visible) {
      if (visible && this.doTick) {
        spacesApp.time.startTicking();
      } else {
        spacesApp.time.stopTicking();
      }
    },
    gyroEnabled(gyroEnabled) {
      if (gyroEnabled) {
        spacesApp.gyro.enableGyro();
      } else {
        spacesApp.gyro.disableGyro();
      }
    },
    gyroDatas(gyroDatas) {
      if (this.gyroEnabled) {
        spacesApp.gyro.orien.a = gyroDatas.alpha;
        spacesApp.gyro.orien.b = gyroDatas.beta;
        spacesApp.gyro.orien.g = gyroDatas.gamma;
      }
    },
    isInGame(isInGame) {
      if (isInGame) {
        spacesApp.sphere.startGame();
      } else {
        spacesApp.sphere.stopGame();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$genericHotspotSizeMobile: 25px;
$genericHotspotSizeDesktop: 35px;
$imgHotspotSize: 40px;

.kalenji-scene {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: grab;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  &.visible {
    opacity: 1;
  }

  .close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    background-color: transparent;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;

    img {
      width: 100%;
    }
  }

  .scene-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .hotspots-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 95;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    * {
      pointer-events: all;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    z-index: 95;
  }

  &.dragging {
    .generic-hotspot,
    .dialog-hotspot,
    .scene-hotspot,
    .hub-hotspot {
      pointer-events: none;
    }
  }

  .generic-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    height: $genericHotspotSizeMobile;
    width: $genericHotspotSizeMobile;
    border-radius: 50px;
    white-space: nowrap;
    border: 3px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    &.hub {
      height: $genericHotspotSizeDesktop * 1.1;
      width: $genericHotspotSizeDesktop * 1.1;

      .legend {
        font-weight: 300;
      }
    }
  }

  .hub-hotspot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #B4001A;
    color: #FFF;
    font-size: 11px;
    border-radius: 50px;
    white-space: nowrap;
    border: 5px solid rgba(255, 255, 255, 0.3);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 6px 11px;
  }

  .dialog-hotspot,
  .scene-hotspot {
    position: absolute;
    width: $imgHotspotSize;
    height: $imgHotspotSize;
    top: -100px;
    left: -100px;
    margin-top: -($imgHotspotSize / 2);
    margin-left: -($imgHotspotSize / 2);
    cursor: pointer;
    z-index: 95;
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot {
    cursor: pointer;

    .img-icon {
      height: $genericHotspotSizeMobile - 11px;
    }

    .hotspot-legend {
      position: absolute;
      top: $imgHotspotSize + 6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #FFF;
      font-size: 13px;
      border-radius: 3px;
      white-space: nowrap;
      border: 8px solid rgba(255, 255, 255, 0.3);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      padding: 6px;
      color: #2c3e50;
      display: none;
      pointer-events: none;
    }

    &:hover {
      .hotspot-legend {
        display: block;
      }
    }
  }

  .scene-hotspot {
    cursor: default;

    img {
      cursor: pointer;
    }
  }

  .generic-hotspot,
  .dialog-hotspot,
  .scene-hotspot,
  .hub-hotspot {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .legend {
      pointer-events: none;
      cursor: default;
    }
  }

  &:not(.mobile) {
    .generic-hotspot {
      height: $genericHotspotSizeDesktop;
      width: $genericHotspotSizeDesktop;

      &:hover {
        border: 2px solid #FFF;
      }

      &.hub.has-legend {
        height: $genericHotspotSizeDesktop * 1.2;
        width: $genericHotspotSizeDesktop * 1.2;
        &:hover {
          width: auto;
          height: $genericHotspotSizeDesktop * 1.4;
          z-index: 100;

          .img-icon-wrapper {
            display: none;
          }
        }
        &:not(:hover) {
          .legend {
            display: none;
          }
        }
      }
    }
  }

  .scene-hotspot {
    .hotspot-legend {
      display: block;
    }
  }

  .generic-hotspot.exit-button {
    bottom: 30px;
    left: 50%;
    top: auto;
    right: auto;
    font-size: 28px;
    height: $genericHotspotSizeMobile * 2;
    width: $genericHotspotSizeMobile * 2;
  }
}

.dialog-hotspot img,
.scene-hotspot img {
  width: 100%;
  height: 100%;
}
</style>
