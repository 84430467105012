<template>
  <div
    class="flex-grow flex-col justify-center align-center lang-selector"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
  >
    <div class="lang-selector-title">Choose your language</div>
    <div class="flex-grow flex-row justify-center align-center w-100">
      <div
        class="flex-row justify-center align-start"
      >
        <div
          class="lang-col mx-20"
          v-for="(colLangs, iCol) in cols"
          :key="`col-${iCol}`"
        >
          <div
            v-for="(lang, iRow) in colLangs"
            class="w-100"
            :key="`col-${iCol}-row${iRow}`"
          >
            <div
              class="kalenji-button border-button button-l clickable w-100 mb-15"
              @click="chooseLang(lang)"
            >
              <div class="flex-row justify-center align-center w-100">
                {{ lang.shortLabel }} - {{ lang.label }}
              </div>
            </div>
            <!-- {{ col }} - {{ row }} = {{ index }} -->
          </div>
        </div>
      </div>
    </div>
    <div class="actions-container">
      <a class="kalenji-button secondary-button" @click="close">
        Back
      </a>
    </div>
  </div>
</template>

<script>
import langs from '@/visit/langs.js';

const LANG_BTN_WIDTH = 300;

export default {
  data: () => ({
    langs,
  }),

  computed: {
    grid() {
      let rows = 3;
      let cols = Math.ceil(langs.length / rows);
      const width = cols * LANG_BTN_WIDTH;

      if (window.innerWidth < width) {
        cols = Math.trunc(window.innerWidth / LANG_BTN_WIDTH);
        rows = Math.ceil(this.langs.length / cols);
      }

      return { cols, rows };
    },
    cols() {
      const cols = [];
      for (let col = 0; col < this.grid.cols; col++) {
        cols[col] = [];
        for (let row = 0; row < this.grid.rows; row++) {
          const langIndex = col * this.grid.rows + row;
          const lang = this.langs[langIndex];
          if (lang) {
            cols[col].push(lang);
          }
        }
      }

      return cols;
    },
  },

  methods: {
    chooseLang(lang) {
      this.$emit(`choose-lang`, lang);
    },
    close() {
      this.$emit(`close`);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-selector {
  .lang-selector-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 100px;
  }

  .kalenji-button {
    background: transparent;
    border: 3px solid #fff;
    font-size: 24px;
    text-align: center;
    padding: 1.1em 16px;
  }

  .lang-col {
    width: 260px;
  }

  .actions-container {
    width: 100%;
    max-width: 300px;
    margin-bottom: 50px;
  }
}
</style>
