
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Eco Diseño`,
      content: `Kalenji utiliza materiales reciclados para el diseño del upper de punto, la suela y la plantilla. ¡El tejido de punto del upper es un 62% de diseño ecológico!`,
    },
    {
      name: `3d-knit`,
      title: `TEJIDO 3D `,
      content: `Kalenji ha diseñado un tejido 3D de punto para el upper, que ofrece un efecto "segunda piel" para el runner que corre con estas zapatillas.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop de 4mm`,
      content: `Kalenji presenta un drop de 4 milímetros en sus Jogflow 500.1 invitando al runner a enfrentar el suelo con el antepié, adoptando un paso más natural y limitando el riesgo de lesiones.
      `,
    },
    {
      name: `eva-foam`,
      title: `Espuma EVA`,
      content: `ara evitar que el pie se sumerja demasiado en la plantilla, el equipo de diseñadores ha creado una suela de espuma EVA acolchada que reduce el impacto en el talón.`,
    },
    {
      name: `flex-h-sole`,
      title: `Suela Flex H `,
      content: `La Jogflow 500.1 presenta una suela Flex H, diseñada para que la zapatilla sea lo más flexible posible. Las muescas flexibles en la suela facilitan el andar y brindan libertad de movimiento al pie, asegurando su rol natural, la amortiguación.`,
    },
  ]
};