<template>
  <div
    class="shoe-presentation"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    :style="`
      background-image: url(${common.backgroundImage});
    `"
  >
    <div class="shoe-presentation-header">
      <button
        @click="close"
      >
        <img src="@/assets/icons/arrow_left_black.svg" alt="go-back" />
      </button>
      <img src="@/assets/logos/kalenji_black.png" alt="kalenji-logo">
    </div>
    <div class="shoe-presentation-selector" v-if="visible && !productPageOpened">
      <label for="currentShoe-500K" :class="{selected: currentShoe === '500K'}">
        <input type="radio" name="currentShoe" v-model="currentShoe" value="500K" id="currentShoe-500K"/>
        500K.1
      </label>
      <label for="currentShoe-500" :class="{selected: currentShoe === '500'}">
        <input type="radio" name="currentShoe" v-model="currentShoe" value="500" id="currentShoe-500" />
        500.1
      </label>
    </div>
    <div class="shoe-presentation-content-wrapper">
      <div class="shoe-presentation-content">
        <div class="shoe-presentation-sections">
          <label
            class="shoe-presentation-section-button"
            v-for="(sct, idx) in shoe.sections"
            :key="idx"
          >
            <input
              type="radio"
              name="currSection"
              :value="sct.name"
              @click="playMultimediaSound"
              v-model="currSection"
            />
            <img
              :src="sct.icon"
              :alt="sct.name"
              v-show="sct.name !== currSection"
            />
            <img
              :src="sct.iconActive"
              :alt="sct.name"
              v-show="sct.name === currSection"
            />
          </label>
        </div>
        <div class="shoe-presentation-model">
          <div class="canvas-container">
            <canvas ref="shoeCanvas" />
          </div>
        </div>
      </div>
      <div class="shoe-presentation-texts">
        <div class="shoe-presentation-description">
          <h3>{{ currentSection.title }}</h3>
          <p>{{ currentSection.content }}</p>
        </div>
        <button
          class="shoe-presentation-cta"
          @click="openProductPage"
        >
          <span>{{ common.cta }}</span>
          <img src="@/assets/icons/chevron_right_white.svg" alt="chevron-right">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Shoes3D from '@/Shoes3D';

let shoes3DApp;

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    productPageOpened: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    presentationConfig: {
      type: Object,
      required: true,
    },
    shoes3dConfig: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    currentShoe: `500K`,
    currSection: `eco-design`,
  }),

  computed: {
    common() {
      return this.presentationConfig.common;
    },
    shoe() {
      return this.presentationConfig[this.currentShoe];
    },
    currentSection() {
      return this.shoe?.sections.find(sct => sct.name === this.currSection);
    },
    shoeCanvas() {
      return this.$refs[`shoeCanvas`];
    },
    envMapsUrls() {
      return this.shoes3dConfig.envMaps.map(envMap => envMap.url);
    },
  },

  methods: {
    openProductPage() {
      this.$emit('open-product-page');
      this.playInterfaceSound();
      this.$gtagEvent(`Get_Jogflow`);
    },
    playInterfaceSound() {
      this.$emit(`play-sfx`, `interface`);
    },
    playMultimediaSound() {
      this.$emit(`play-sfx`, `multimedia`);
    },
    close() {
      this.$emit('close-shoe-presentation');
    },
    setShoe() {
      const shoeModel = this.currentShoe === `500` ? `shoeA` : `shoeB`;
      shoes3DApp.action.selectShoe(shoeModel);
      shoes3DApp.action.initRot(0, 0);
    },
    setShoePov() {
      const shoePosition = this.currentSection.shoePosition;
      shoes3DApp.action.setPov(shoePosition.azimuth, shoePosition.polar);
    },
    async createShoes3D() {
      shoes3DApp = new Shoes3D(this.shoeCanvas);
      const envMap = await shoes3DApp.addAssets({
        type: 'cub',
        name: 'envMap',
        url: this.envMapsUrls,
      }, {});
      shoes3DApp.setLighting(envMap);

      const shoesHandler = {};
      await shoes3DApp.addAssets(this.shoes3dConfig.assets, shoesHandler);
      shoes3DApp.setShoes(shoesHandler);
      this.setShoe();
      shoes3DApp.shoes.on(`clicked`, this.shoeClicked);
    },
    shoeClicked() {
      this.$gtagEvent(`3D_shoe`);
    },
  },

  async mounted() {
    this.createShoes3D();
  },

  watch: {
    currentShoe(selectedShoe) {
      this.currSection = (selectedShoe === `500` ? `only-200g` : `eco-design`);
      this.setShoe();
    },
    currSection() {
      this.setShoePov();
    },
    visible(visible) {
      if (visible) {
        shoes3DApp.time.startTicking();
      } else {
        shoes3DApp.time.stopTicking();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shoe-presentation {
  padding: 20px 25px;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 100% 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  [type=radio] {
    position: absolute;
    opacity: 0;
  }

  button {
    cursor: pointer;
  }

  .shoe-presentation-header,
  .shoe-presentation-selector {
    height: 100%;
    margin: 10px 0;
    max-height: 50px;
  }

  .shoe-presentation-selector {
    &, * {
      cursor: pointer;
    }
  }

  .shoe-presentation-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 25%;
      max-width: 300px;
    }

    button {
      position: absolute;
      top: 50%;
      left: 0;
      border: none;
      transform: translateY(-50%);
      background: none;

      img {
        width: 20px;
      }
    }
  }

  .shoe-presentation-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 160px;

    label {
      width: 50%;
      display: inline-block;
      border: 1px solid #2c2e2f;
      padding: 3px 10px;
      border-radius: 5px;
      color: #2c2e2f;

      &:first-child {
        border-right: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &:last-child {
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      &.selected {
        background: #2c2e2f;
        color: #fff;
      }

    }
  }

  .shoe-presentation-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .shoe-presentation-content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      .shoe-presentation-sections {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .shoe-presentation-section-button {
          cursor: pointer;
        }

        label {
          margin: 5px 0;
          img {
            width: 45px;
          }
        }
      }

      .shoe-presentation-model {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        .canvas-container {
          width: 100%;
          padding-bottom: 100%;
        }
        canvas {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }

    .shoe-presentation-description {
      width: 100%;
      height: 32vh;
      min-height: 210px;
      padding-bottom: 40px;
      color: #fff;
      text-align: left;

      h3 {
        font-weight: 700;
        font-size: 20px;
        margin: 0;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
      }
    }

    .shoe-presentation-cta {
      width: 100%;
      padding: 15px 0;
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: #2c2e2f;
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
      cursor: pointer;

      & > * {
        margin: 0 5px;
      }

      img {
        height: 20px;
      }
    }
  }

  &:not(.mobile) {
    padding: calc(2.5% + 20px) calc(2.5% + 25px);
    background-color: rgba(0, 0, 0, 0.85);
    background-repeat: no-repeat;
    background-size: 95% 95%;
    background-position: center;

    .shoe-presentation-header {

      img {
        max-width: 180px;
      }

      button {
        img {
          width: 30px;
        }
      }
    }

    .shoe-presentation-selector {
      min-width: 220px;
    }

    .shoe-presentation-content-wrapper {
      flex-direction: row;
      width: 80%;
      flex-grow: 1;

      .shoe-presentation-content {
        width: calc(100% * 2 / 3);

        .shoe-presentation-model {
          padding: 10%;

          .canvas-container {
            position: relative;
          }
        }
      }
      .shoe-presentation-texts {
        width: calc(100% / 3);

        .shoe-presentation-description {
          h3 {
            font-size: 30px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .shoe-presentation-cta {
          position: static;
        }
      }

      // & > * {
      //   width: 50%;
      // }

    }
  }
}
</style>