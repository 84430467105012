
export default {
  name: `500.1`,
  sections: [
    {
      name: `only-200g`,
      title: `Apenas 200g`,
      content: `Para tirar maior partido de cada passada, o Jogflow 500.1 pesa apenas 200 gramas!`,
    },
    {
      name: `ventilated-mesh`,
      title: `Mesh de rede ventilada`,
      content: `A mesh de rede ultra-ventilada proporciona uma melhor respirabilidade do pé e permite ao corredor desfrutar apenas o momento, bem seco.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop de 4mm`,
      content: `A Kalenji oferece um drop de 4 milímetros em seus Jogflow 500.1 para convidar o corredor a atacar o solo com a parte da frente do pé, ajudando-o a a adotar uma passada mais natural e limitar o risco de lesões.`,
    },
    {
      name: `eva-foam`,
      title: `Espuma EVA`,
      content: `Para evitar que o pé se afunde demasiado na palmilha, as equipes de design criaram uma espuma de amortecimento em EVA para reduzir o impacto no calcanhar.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Sole`,
      content: `Os Jogflow 500.1 beneficiam de uma sola Flex H, concebida para dar ao sapato a maior flexibilidade possível. Estes entalhes flexíveis na sola permitem uma boa passada e proporcionam liberdade de movimentos do pé, enquanto garantem a sua função natural de amortecedor.`,
    },
  ]
};
