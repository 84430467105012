<template>
  <base-tutorial :visible="visible" @click="handleClick">
    <div class="h-100 flex-col justify-end align-center">
      <img class="tuto-gif" :src="tutoGif" alt="tutorial-gif">
      <p class="tuto-content">{{ tutoContent }}</p>
      <button
        class="kalenji-button white-button"
        @click="close"
        v-if="$breakpoints.isMobile"
      >
        {{ tutoCTA }}
      </button>
    </div>
  </base-tutorial>
</template>

<script>
import BaseTutorial from './BaseTutorial';

export default {
  components: {
    BaseTutorial,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    config: {
      type: Object,
      required: true,
    }
  },

  computed: {
    tutoGif() {
      return this.config?.gif ?? ``;
    },
    tutoContent() {
      return this.config?.content ?? ``;
    },
    title() {
      return this.config?.title ?? ``;
    },
    tutoCTA() {
      return this.config?.cta ?? ``;
    },
  },

  methods: {
    handleClick() {
      if (!this.$breakpoints.isMobile) {
        this.close();
      }
    },
    close() {
      this.$emit(`close-tutorial`);
    }
  }
};
</script>

<style lang="scss" scoped>

.tuto-gif {
  width: 25%;
  max-width: 100px;
}

.kalenji-button {
  padding: 15px 30px;
  margin: 20px 0;
}

</style>
