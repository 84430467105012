export default [
  {
    name: `deep-blue`,
    colorKey: `blue`,
    icon: require(`@/assets/products/500/man/blue/500_man_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/man/blue/carousel/500_man_slide_1.jpg`),
      require(`@/assets/products/500/man/blue/carousel/500_man_slide_2.jpg`),
      require(`@/assets/products/500/man/blue/carousel/500_man_slide_3.jpg`),
      require(`@/assets/products/500/man/blue/carousel/500_man_slide_4.jpg`),
    ],
    sizes: [
      { value: 39, sku: 4400709 },
      { value: 40, sku: 4400710 },
      { value: 41, sku: 4400711 },
      { value: 42, sku: 4400712 },
      { value: 43, sku: 4400713 },
      { value: 44, sku: 4400714 },
      { value: 45, sku: 4400715 },
      { value: 46, sku: 4400716 },
      { value: 47, sku: 4400717 },
    ],
  },
  {
    name: `black`,
    colorKey: `black`,
    icon: require(`@/assets/products/500/man/black/500_man_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/man/black/carousel/500_man_slide_1.jpg`),
      require(`@/assets/products/500/man/black/carousel/500_man_slide_2.jpg`),
      require(`@/assets/products/500/man/black/carousel/500_man_slide_3.jpg`),
      require(`@/assets/products/500/man/black/carousel/500_man_slide_4.jpg`),
    ],
    sizes: [
      { value: 39, sku: 4406299 },
      { value: 40, sku: 4406300 },
      { value: 41, sku: 4406301 },
      { value: 42, sku: 4406302 },
      { value: 43, sku: 4406303 },
      { value: 44, sku: 4406304 },
      { value: 45, sku: 4406306 },
      { value: 46, sku: 4406307 },
      { value: 47, sku: 4406308 },
    ],
  },
  {
    name: `carbon-grey`,
    colorKey: `grey`,
    icon: require(`@/assets/products/500/man/grey/500_man_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/man/grey/carousel/500_man_slide_1.jpg`),
      require(`@/assets/products/500/man/grey/carousel/500_man_slide_2.jpg`),
      require(`@/assets/products/500/man/grey/carousel/500_man_slide_3.jpg`),
      require(`@/assets/products/500/man/grey/carousel/500_man_slide_4.jpg`),
    ],
    sizes: [
      { value: 39, sku: 4406251 },
      { value: 40, sku: 4406252 },
      { value: 41, sku: 4406253 },
      { value: 42, sku: 4406254 },
      { value: 43, sku: 4406255 },
      { value: 44, sku: 4406256 },
      { value: 45, sku: 4406257 },
      { value: 46, sku: 4406258 },
      { value: 47, sku: 4406260 },
    ],
  },
  {
    name: `snow-white`,
    colorKey: `white`,
    icon: require(`@/assets/products/500/man/white/500_man_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/man/white/carousel/500_man_slide_1.jpg`),
      require(`@/assets/products/500/man/white/carousel/500_man_slide_2.jpg`),
      require(`@/assets/products/500/man/white/carousel/500_man_slide_3.jpg`),
      require(`@/assets/products/500/man/white/carousel/500_man_slide_4.jpg`),
    ],
    sizes: [
      { value: 39, sku: 4406281 },
      { value: 40, sku: 4406282 },
      { value: 41, sku: 4406283 },
      { value: 42, sku: 4406284 },
      { value: 43, sku: 4406285 },
      { value: 44, sku: 4406286 },
      { value: 45, sku: 4406287 },
      { value: 46, sku: 4406288 },
      { value: 47, sku: 4406289 },
    ],
  },
];
