export default [
  {
    name: `greeting_page`,
    content: {
      title: `GRATULACJE`,
      subtitle: `Masz szansę wygrać strój Kalenji`,
      cta: `Kontynuuj`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Dziękujemy za udział`,
      subtitle: ``,
      cta: `Wróć do salonu`,
    },
  },
  {
    name: `game_form`,
    title: `WYPEŁNIJ FORMULARZ UCZESTNICTWA`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Imię`,
      },
      {
        name: `lastname`,
        placeholder: `Nazwisko`,
      },
      {
        name: `email`,
        placeholder: `E-mail`,
      },
    ],
    formSubmitText: `Wypróbuj swoje szczęście`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Może następnym razem?`,
    ],
  }
];