export default [
  {
    name: `black`,
    title: `Negro`,
    sizes: [
      { value: 39, price: `49,99€` },
      { value: 40, price: `49,99€` },
      { value: 41, price: `49,99€` },
      { value: 42, price: `49,99€` },
      { value: 43, price: `49,99€` },
      { value: 44, price: `49,99€` },
      { value: 45, price: `49,99€` },
      { value: 46, price: `49,99€` },
      { value: 47, price: `49,99€` },
    ],
  },
  {
    name: `khaki`,
    title: `Verde oscuro`,
    sizes: [
      { value: 39, price: `49,99€` },
      { value: 40, price: `49,99€` },
      { value: 41, price: `49,99€` },
      { value: 42, price: `49,99€` },
      { value: 43, price: `49,99€` },
      { value: 44, price: `49,99€` },
      { value: 45, price: `49,99€` },
      { value: 46, price: `49,99€` },
      { value: 47, price: `49,99€` },
    ],
  },
];