export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Eco design`,
      content: `Kalenji uses recycled materials for the design of its knitted upper as well as the inner and outer soles. The knitted upper is 62% eco-designed!`,
    },
    {
      name: `3d-knit`,
      title: `3D Knit`,
      content: `Kalenji has designed a 3D Knit upper offering a real "second skin" effect to the runner who wears them.`,
    },
    {
      name: `4mm-drop`,
      title: `4mm Drop`,
      content: `Kalenji offer a 4 millimeter drop to its Jogflow 500K.1 to invite the runner to attack the ground with the forefoot and thus help him adopt a more natural stride and limit the risk of injuries.`,
    },
    {
      name: `eva-foam`,
      title: `EVA Foam`,
      content: `To prevent the foot from sinking too far into the sole, the design teams create a soft EVA foam cushioning to reduce the attack on the heel.`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H Sole`,
      content: `The Jogflow 500K.1 benefits from a Flex H sole, designed to keep the shoe as flexible as possible. These flex notches on the sole allow for each stride and provide freedom of movements for the foot while ensuring its natural role as a shock absorber.`,
    },
  ]
};
