import * as THREE from 'three'
import EvEmitter from './EvEmitter'

export default class Ray extends EvEmitter {
	constructor(ap3) {
		super()
		this.init(ap3)
	}

	init(ap3) {
		this.ap3 = ap3
		this.ray = new THREE.Raycaster()
		this.ray.near = 0.1
		this.ray.far = 11
		this.castListHome = []
		this.castListGame = []
		this.castList = this.castListHome
		this.curCastList = []
	}

	cast() {
		this.ray.setFromCamera(this.ap3.pointer.pointer, this.ap3.cam.cam)
		this.curCastList = this.ray.intersectObjects(this.castList)
		if(this.curCastList.length) {
			this.trigger(this.curCastList[0].object.name)
		} else {
			this.trigger('gameLose', [this.ap3.pointer.pointer])
		}
	}

	tick() {
		this.ray.setFromCamera(this.ap3.pointer.pointer, this.ap3.cam.cam)
		this.intersects = this.ray.intersectObjects(this.castList)
	}
}