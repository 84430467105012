export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      icon: require(`@/assets/shoe_presentation/icons/eco.png`),
      iconActive: require(`@/assets/shoe_presentation/icons/eco_active.png`),
      shoePosition: {
        azimuth: 0,
        polar: 0,
      },
    },
    {
      name: `3d-knit`,
      icon: require(`@/assets/shoe_presentation/icons/3D_knit.png`),
      iconActive: require(`@/assets/shoe_presentation/icons/3D_knit_active.png`),
      shoePosition: {
        azimuth: 50,
        polar: 30,
      },
    },
    {
      name: `4mm-drop`,
      icon: require(`@/assets/shoe_presentation/icons/drop.png`),
      iconActive: require(`@/assets/shoe_presentation/icons/drop_active.png`),
      shoePosition: {
        azimuth: 90,
        polar: 90,
      },
    },
    {
      name: `eva-foam`,
      icon: require(`@/assets/shoe_presentation/icons/foam.png`),
      iconActive: require(`@/assets/shoe_presentation/icons/foam_active.png`),
      shoePosition: {
        azimuth: -40,
        polar: -25,
      },
    },
    {
      name: `flex-h-sole`,
      icon: require(`@/assets/shoe_presentation/icons/sole.png`),
      iconActive: require(`@/assets/shoe_presentation/icons/sole_active.png`),
      shoePosition: {
        azimuth: 95,
        polar: 15,
      },
    },
  ]
};
