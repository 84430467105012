export default [
  {
    name: `black`,
    title: `Black`,
    sizes: [
      { value: 39, price: `£44.99` },
      { value: 40, price: `£44.99` },
      { value: 41, price: `£44.99` },
      { value: 42, price: `£44.99` },
      { value: 43, price: `£44.99` },
      { value: 44, price: `£44.99` },
      { value: 45, price: `£44.99` },
      { value: 46, price: `£44.99` },
      { value: 47, price: `£44.99` },
    ],
  },
  {
    name: `khaki`,
    title: `Khaki`,
    sizes: [
      { value: 39, price: `£44.99` },
      { value: 40, price: `£44.99` },
      { value: 41, price: `£44.99` },
      { value: 42, price: `£44.99` },
      { value: 43, price: `£44.99` },
      { value: 44, price: `£44.99` },
      { value: 45, price: `£44.99` },
      { value: 46, price: `£44.99` },
      { value: 47, price: `£44.99` },
    ],
  },
];