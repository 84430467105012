<template>
  <div
    class="game-modal"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    :style="`
      background-image: url(${commonConfig.backgroundImage});
    `"
  >
    <div
      class="game-modal-greeting"
      v-if="!isGreetingPassed || isFormComplete"
    >
      <div class="game-modal-greeting-shoe">
        <img :src="greetingConfig.shoeImage" alt="kalenji-shoe" class="kalenji-shoe" />
        <img :src="greetingConfig.sparkle"   alt="sparkle"      class="sparkle sparkle-1" />
        <img :src="greetingConfig.sparkle"   alt="sparkle"      class="sparkle sparkle-2" />
        <img :src="greetingConfig.sparkle"   alt="sparkle"      class="sparkle sparkle-3" />
        <img :src="greetingConfig.sparkle"   alt="sparkle"      class="sparkle sparkle-4" />
        <img :src="greetingConfig.sparkle"   alt="sparkle"      class="sparkle sparkle-5" />
      </div>
      <div class="game-modal-greeting-content">
        <div class="game-modal-back-btn">
          <button @click="close" v-show="!isGreetingPassed">
            <img :src="commonConfig.backButton" alt="back-btn">
          </button>
        </div>
        <h3 class="greeting-content-title">{{ currentModalConfig.content.title }}</h3>
        <h4 class="greeting-content-subtitle" v-show="!isGreetingPassed">{{ currentModalConfig.content.subtitle }}</h4>
        <img class="greeting-content-logo" :src="greetingConfig.logo" alt="flow-logo" v-show="$breakpoints.isMobile"/>
        <button class="greeting-content-cta" @click="switchModal">{{ currentModalConfig.content.cta }}</button>
      </div>
    </div>
    <div class="game-modal-form-wrapper" v-else-if="!isFormComplete">
      <div class="game-modal-back-btn">
        <button @click="close">
          <img :src="commonConfig.backButton" alt="back-btn">
        </button>
      </div>
      <div class="game-modal-form-inputs">
        <h2 class="game-modal-form-title">{{ formConfig.title }}</h2>
        <div class="spacer" v-if="$breakpoints.isMobile"/>
        <div class="game-modal-form">
          <input
            :type="field.type"
            :name="field.name"
            :placeholder="field.placeholder"
            :key="`${field.name}-${idx}`"
            v-model="mailData[field.name]"
            class="input-field"
            :required="true"
            v-for="(field, idx) in formConfig.fields"
          />
        </div>
        <button @click="sendMail" class="form-submit" >{{ formConfig.formSubmitText }}</button>
        <div class="spacer" v-if="!$breakpoints.isMobile"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    lang: {
      type: Object,
      required: true,
    },
    gameConfig: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    isGreetingPassed: false,
    isFormComplete: false,
    mailData: {
      firstname: ``,
      lastname: ``,
      email: ``,
    },
  }),

  computed: {
    commonConfig() {
      return this.gameConfig.find(e => e.name === `common_assets`);
    },
    greetingConfig() {
      return this.gameConfig.find(e => e.name === `greeting_page`);
    },
    thankConfig() {
      return this.gameConfig.find(e => e.name === `thank_page`);
    },
    formConfig() {
      return this.gameConfig.find(e => e.name === `game_form`);
    },
    currentModalConfig() {
      return this.isGreetingPassed ? this.thankConfig : this.greetingConfig;
    },
  },

  methods: {
    close() {
      this.$emit(`close-game-modal`);
      this.$gtagEvent(`Complete_Game`);
    },
    switchModal() {
      if (!this.isGreetingPassed) {
        this.isGreetingPassed = true;
      } else {
        this.close();
      }
    },
    sendMail() {
      const clientMail = {
        lang: this.lang.key,
        ...this.mailData,
        to: this.mailData.email,
      };
      const companyMail = {
        ...this.mailData,
        lang: this.lang.key,
        to: this.formConfig.companyEmail,
      };
      axios.post(
        `${this.formConfig.mailerBaseUrl}${this.formConfig.clientUUID}`,
        clientMail,
      );
      axios.post(
        `${this.formConfig.mailerBaseUrl}${this.formConfig.companyUUID}`,
        companyMail,
      );
      this.isFormComplete = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.game-modal {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 100% 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  color: #fff;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .game-modal-back-btn {
    width: 100%;
    padding: 15px 30px;
    display: flex;
    justify-content: flex-start;

    button {
      background: none;
      border: none;
      color: #fff;

      img {
        width: 25px;
      }
    }
  }

  .game-modal-greeting {
    width: 100%;
    height: 100%;

    .game-modal-greeting-shoe {
      width: 100%;
      height: 100%;
      position: relative;

      .kalenji-shoe {
        position: absolute;
        height: 45%;
        top: 17.5%;
        left: 50%;
        transform: translateX(-50%);
      }

      .sparkle {
        position: absolute;
        max-height: 8%;
        transform: translateX(-50%);

        &.sparkle-1 {
          top: 24%;
          left: calc(50% - 80px);
        }

        &.sparkle-2 {
          top: 18%;
          left: calc(50% + 40px);
          max-height: 13%;
        }

        &.sparkle-3 {
          top: 27%;
          left: calc(50% + 60px);
        }

        &.sparkle-4 {
          top: 47%;
          left: calc(50% - 100px);
          max-height: 13%;
        }

        &.sparkle-5 {
          top: 63%;
          left: calc(50% + 120px);
        }
      }
    }

    .game-modal-greeting-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;

      .greeting-content-title {
        width: 70%;
        margin: 0;
        font-size: 25px;
      }

      .greeting-content-subtitle {
        width: 70%;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }

      .greeting-content-logo {
        margin-top: auto;
        width: 40%;
        max-width: 280px;
      }

      .greeting-content-cta {
        width: 100%;
        padding: 15px 0;
        margin-top: 90px;
        border: none;
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        color: #fff;
        background-color: #2c2e2f;
      }
    }
  }

  .game-modal-form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .game-modal-form-title {
      font-size: 25px;
      padding: 0 1.5em;
    }

    .game-modal-form-inputs {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .game-modal-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .input-field {
          width: 70%;
          margin: 20px 0;
          padding: 15px 30px;
          text-align: center;
          font-size: 20px;
          font-family: 'Poppins', sans-serif;
          background-color: #ffffff82;
          box-shadow: rgb(255 255 255 / 43%) 0px 0px 10px;
          border: none;
          border-radius: 40px;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .form-submit {
      width: 100%;
      padding: 15px 0;
      margin-bottom: 0;
      border: none;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      color: #fff;
      background-color: #2c2e2f;
      cursor: pointer;
    }
  }

  &:not(.mobile) {
    .game-modal-greeting-content {
      padding-bottom: 8%;

      button {
        cursor: pointer;
      }

      .game-modal-back-btn {
        padding: 30px;

        img {
          width: 35px;
        }
      }

      .greeting-content-title {
        font-size: 30px;
      }

      .greeting-content-subtitle {
        font-size: 24px;
        font-weight: 300;
      }

      .greeting-content-cta {
        margin-top: auto;
        width: auto;
        padding: 10px 30px;
      }
    }

    .game-modal-form-wrapper {

      .game-modal-back-btn {
        padding: 30px;

        img {
          width: 35px;
        }
      }

      .game-modal-form-inputs {
        width: auto;

        .input-field {
          width: 100%;
          padding: 15px 30px;
        }
      }
    }
  }
}
</style>