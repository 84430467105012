export default [
  {
    name: `laurel-green`,
    colorKey: `green`,
    icon: require(`@/assets/products/500/woman/green/500_woman_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/woman/green/carousel/500_woman_slide_1.jpg`),
      require(`@/assets/products/500/woman/green/carousel/500_woman_slide_2.jpg`),
      require(`@/assets/products/500/woman/green/carousel/500_woman_slide_3.jpg`),
      require(`@/assets/products/500/woman/green/carousel/500_woman_slide_4.jpg`),
    ],
    sizes: [
      { value: 36, sku: 4047726 },
      { value: 37, sku: 4047727 },
      { value: 38, sku: 4047728 },
      { value: 39, sku: 4047729 },
      { value: 40, sku: 4047730 },
      { value: 41, sku: 4047731 },
      { value: 42, sku: 4047732 },
    ]
  },
  {
    name: `black`,
    colorKey: `black`,
    icon: require(`@/assets/products/500/woman/black/500_woman_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/woman/black/carousel/500_woman_slide_1.jpg`),
      require(`@/assets/products/500/woman/black/carousel/500_woman_slide_2.jpg`),
      require(`@/assets/products/500/woman/black/carousel/500_woman_slide_3.jpg`),
      require(`@/assets/products/500/woman/black/carousel/500_woman_slide_4.jpg`),
    ],
    sizes: [
      { value: 36, sku: 4047733 },
      { value: 37, sku: 4047734 },
      { value: 38, sku: 4047735 },
      { value: 39, sku: 4047736 },
      { value: 40, sku: 4047737 },
      { value: 41, sku: 4047738 },
      { value: 42, sku: 4047739 },
    ]
  },
  {
    name: `orange`,
    colorKey: `orange`,
    icon: require(`@/assets/products/500/woman/orange/500_woman_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/woman/orange/carousel/500_woman_slide_1.jpg`),
      require(`@/assets/products/500/woman/orange/carousel/500_woman_slide_2.jpg`),
      require(`@/assets/products/500/woman/orange/carousel/500_woman_slide_3.jpg`),
      require(`@/assets/products/500/woman/orange/carousel/500_woman_slide_4.jpg`),
    ],
    sizes: [
      { value: 36, sku: 4406341 },
      { value: 37, sku: 4406342 },
      { value: 38, sku: 4406343 },
      { value: 39, sku: 4406344 },
      { value: 40, sku: 4406345 },
      { value: 41, sku: 4406346 },
      { value: 42, sku: 4406347 },
    ]
  },
  {
    name: `foggy-blue`,
    colorKey: `blue`,
    icon: require(`@/assets/products/500/woman/grey/500_woman_icon.jpg`),
    carousel: [
      require(`@/assets/products/500/woman/grey/carousel/500_woman_slide_1.jpg`),
      require(`@/assets/products/500/woman/grey/carousel/500_woman_slide_2.jpg`),
      require(`@/assets/products/500/woman/grey/carousel/500_woman_slide_3.jpg`),
    ],
    sizes: [
      { value: 36, sku: 4406358 },
      { value: 37, sku: 4406359 },
      { value: 38, sku: 4406360 },
      { value: 39, sku: 4406361 },
      { value: 40, sku: 4406362 },
      { value: 41, sku: 4406363 },
      { value: 42, sku: 4406364 },
    ]
  },
];
