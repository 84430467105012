
export default [
  {
    name: `background_image`,
    image: require(`@/assets/background/background_mobile.jpg`)
  },
  {
    name: `kalenji_white_logo`,
    image: require(`@/assets/logos/kalenji_white.png`),
  },
  {
    name: `kalenji_grey_logo`,
    image: require(`@/assets/logos/kalenji_grey.png`),
  },
  {
    name: `kalenji_flow_logo`,
    image: require(`@/assets/logos/kalenji_flow.png`),
  },
  {
    name: `start_btn`,
  },
];
