
export default class Action {
	constructor(ap3) {
		this.init(ap3)
		this.selectShoe('shoeB')
	}

	init(ap3) {
		this.ap3 = ap3
		this.params = {
			speedDrag: 1,				// Vitesse de la rotation en réponse au scroll
			speedTransit: 1,		// Durée de la transition POV en secondes
			amorti: 5,					// Amorti des rotations des shoes
			safeZoneFactor: 1,	// % de la largeur d'écran à balayer avant dragging
			minAzi: -75,				// Shoes angle vert. mini
			maxAzi: 75					// Shoes angle vert. maxi
		}
		this.curShoe = null
		this.target = { x: 0, y: 0 }
		this.iTarget = { x: 0, y: 0 }
		this.ap3.shoes.action_01.setDuration(this.params.speedTransit)
		this.initRot(0, 0)
	}

	selectShoe(model) {
		if(model == 'shoeA' && this.curShoe != 'shoeA') {
			this.ap3.shoes.hRig2.add(this.ap3.shoes.hShoeA)
			this.ap3.shoes.hRig2.remove(this.ap3.shoes.hShoeB)
			this.curShoe = 'shoeA'
		}
		if(model == 'shoeB' && this.curShoe != 'shoeB') {
			this.ap3.shoes.hRig2.add(this.ap3.shoes.hShoeB)
			this.ap3.shoes.hRig2.remove(this.ap3.shoes.hShoeA)
			this.curShoe = 'shoeB'
		}
	}

	initRot(lon, azi) {
		this.iTarget.x = azi * Math.PI / 180
		this.iTarget.y = - (lon + 90) * Math.PI / 180
		this.target.x = azi * Math.PI / 180
		this.target.y = - (lon + 90) * Math.PI / 180
		this.ap3.shoes.hRig1.rotation.x = azi * Math.PI / 180
		this.ap3.shoes.hRig2.rotation.y = - (lon + 90) * Math.PI / 180
	}

	setITarget() {
		this.iTarget.x = this.ap3.shoes.hRig1.rotation.x
		this.iTarget.y = this.ap3.shoes.hRig2.rotation.y
		this.ap3.time.remEvent('transit')
	}

	setPov(lon, azi) {
		this.setITarget()
		this.target.x = azi * Math.PI / 180
		this.target.y = - (lon + 90) * Math.PI / 180
		this.iTarget.y = (this.iTarget.y + Math.PI*101)%(Math.PI*2) - Math.PI
		this.target.y = (this.target.y + Math.PI*101)%(Math.PI*2) - Math.PI
		if(this.iTarget.y - this.target.y > Math.PI) { this.target.y += Math.PI*2 } else
		if(this.iTarget.y - this.target.y < - Math.PI) { this.target.y -= Math.PI*2 }
		this.ap3.time.addEvent('transit')
		this.ap3.time.remEvent('rig')
		this.ap3.shoes.action_01.reset()
		this.ap3.shoes.action_01.play()
	}

	tickTarget() {
		this.target.x = this.iTarget.x - (this.ap3.pointer.pointer.y - this.ap3.pointer.iPointer.y) * Math.PI/2 * this.params.speedDrag
		this.target.x = Math.min(this.params.maxAzi * Math.PI/180, Math.max(this.params.minAzi * Math.PI/180, this.target.x))
		this.target.y = this.iTarget.y + (this.ap3.pointer.pointer.x - this.ap3.pointer.iPointer.x) * Math.PI/2 * this.params.speedDrag
	}

	tickRig() {
		const taux = 2 - Math.exp(this.ap3.time.delta/1000 * this.params.amorti)
		this.ap3.shoes.hRig1.rotation.x = this.ap3.shoes.hRig1.rotation.x * taux + this.target.x * (1 - taux)
		this.ap3.shoes.hRig2.rotation.y = this.ap3.shoes.hRig2.rotation.y * taux + this.target.y * (1 - taux)
		if(!this.ap3.pointer.dragging && Math.abs(this.ap3.shoes.hRig2.rotation.y - this.target.y) < 0.01) { this.ap3.time.remEvent('rig') }
	}

	tickTransit() {
		this.ap3.shoes.mixer.update(this.ap3.time.delta/1000)
		this.ap3.shoes.hRig1.rotation.x = this.iTarget.x * (1 - this.ap3.shoes.fracs.frac_01) + this.target.x * this.ap3.shoes.fracs.frac_01
		this.ap3.shoes.hRig2.rotation.y = this.iTarget.y * (1 - this.ap3.shoes.fracs.frac_01) + this.target.y * this.ap3.shoes.fracs.frac_01
		if(this.ap3.shoes.fracs.frac_01 == 1) { this.ap3.time.remEvent('transit') }
	}
}