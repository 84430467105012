
export default [
  {
    name: `intro-video`,
    src: require(`@/assets/intro/intro.mp4`),
  },
  {
    name: `breathability`,
    src: require(`@/assets/sphere_videos/breathability.mp4`),
    hasSound: true,
  },
  {
    name: `comfort`,
    src: require(`@/assets/sphere_videos/comfort.mp4`),
    hasSound: true,
  },
  {
    name: `injuries-prevention`,
    src: require(`@/assets/sphere_videos/injuries-prevention.mp4`),
    hasSound: true,
  },
  {
    name: `flow-500k-1`,
    src: require(`@/assets/sphere_videos/flow-500k-1.mp4`),
    hasSound: true,
  },
  {
    name: `lightness`,
    src: require(`@/assets/sphere_videos/lightness.mp4`),
    hasSound: true,
  },
  {
    name: `technicity-shoes`,
    src: require(`@/assets/sphere_videos/technicity-shoes.mp4`),
    hasSound: false,
  },
];
