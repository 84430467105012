export default [
  {
    name: `introduction_tutorial`,
    content: `Explore a 360°`,
    cta: `Continue`,
  },
  {
    name: `game_tutorial`,
    title: `VAMOS JOGAR!`,
    content: `Clique para encontrar o Jogflow nas nuvens para uma chance de ganhar um conjunto de roupa Kalenji`,
    cta: `Continue`,
  },
]
