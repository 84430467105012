<template>
  <div id="clouds-background-wrapper">
    <div
      id="clouds-background"
      :class="{
        mobile: $breakpoints.isMobile
      }"
      :style="`background-image: url(${backgroundImage});`"
    >
      <img
        v-for="(img, idx) in backgroundElements"
        :key="idx"
        :src="img.path"
        :alt="img.name"
        :style="`
          ${Object.keys(img.styles).map(p => `${p}: ${img.styles[p]};`).join('')}
        `"
        v-show="img.context.includes(context)"
      />
    </div>
    <div id="clouds-content-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cloudsConfig: {
      type: Array,
      required: false,
      default: () => [],
    },
    context: {
      type: String,
      required: true,
      default: () => "default",
    }
  },

  computed: {
    backgroundImage() {
      return this.getConfigItem(`background_image`)?.image || ``;
    },
    backgroundElements() {
      return this.getConfigItem(`background_elements`)?.elems || ``;
    }
  },

  methods: {
    getConfigItem(name) {
      return this.cloudsConfig.find(item => item.name === name) || null;
    },
  },
}
</script>

<style lang="scss" scoped>
  #clouds-background-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    #clouds-background {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 0;
      filter: saturate(120%) sepia(10%) opacity(80%);

      & img {
        position: absolute;
      }
    }

    #clouds-content-wrapper {
      position: absolute;
      top: 0;
      height: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
</style>