export default [
  {
    name: `greeting_page`,
    content: {
      title: `PARABÉNS`,
      subtitle: `Você tem uma chance para ganhar um conjunto de roupa Kalenji`,
      cta: `Continuar`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Obrigado pela sua participação`,
      subtitle: ``,
      cta: `Retorne ao showroom`,
    },
  },
  {
    name: `game_form`,
    title: `PREENCHA O FORMULÁRIO PARA PARTICIPAR`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Nome`,
      },
      {
        name: `lastname`,
        placeholder: `Sobrenome`,
      },
      {
        name: `email`,
        placeholder: `Email`,
      },
    ],
    formSubmitText: `Tente sua sorte`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Talvez para a próxima?`,
     
    ],
  }
];
