export default [
  {
    name: `black`,
    colorKey: `black`,
    icon: require(`@/assets/products/500K/man/black/500K_man_icon.jpg`),
    carousel: [
      require(`@/assets/products/500K/man/black/carousel/500K_man_slide_1.jpg`),
      require(`@/assets/products/500K/man/black/carousel/500K_man_slide_2.jpg`),
      require(`@/assets/products/500K/man/black/carousel/500K_man_slide_3.jpg`),
      require(`@/assets/products/500K/man/black/carousel/500K_man_slide_4.jpg`),
    ],
    sizes: [
      { value: 39, sku: 4406465 },
      { value: 40, sku: 4406466 },
      { value: 41, sku: 4406467 },
      { value: 42, sku: 4406468 },
      { value: 43, sku: 4406469 },
      { value: 44, sku: 4406470 },
      { value: 45, sku: 4406471 },
      { value: 46, sku: 4406472 },
      { value: 47, sku: 4406473 },
    ],
  },
  {
    name: `khaki`,
    colorKey: `kaki`,
    icon: require(`@/assets/products/500K/man/kaki/500K_man_icon.jpg`),
    carousel: [
      require(`@/assets/products/500K/man/kaki/carousel/500K_man_slide_1.jpg`),
      require(`@/assets/products/500K/man/kaki/carousel/500K_man_slide_2.jpg`),
      require(`@/assets/products/500K/man/kaki/carousel/500K_man_slide_3.jpg`),
      require(`@/assets/products/500K/man/kaki/carousel/500K_man_slide_4.jpg`),
    ],
    sizes: [
      { value: 39, sku: 4201132 },
      { value: 40, sku: 4201133 },
      { value: 41, sku: 4201134 },
      { value: 42, sku: 4201135 },
      { value: 43, sku: 4201136 },
      { value: 44, sku: 4201137 },
      { value: 45, sku: 4201138 },
      { value: 46, sku: 4201139 },
      { value: 47, sku: 4201140 },
    ],
  },
];
