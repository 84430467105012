
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `环保设计`,
      content: `Kalenji使用回收材料制作了跑鞋的针织鞋面以及内部和外部鞋底。 针织鞋面62%的成分采用环保设计！`,
    },
    {
      name: `3d-knit`,
      title: `3D Knit`,
      content: `Kalenji设计了一种名为3D Knit的鞋面，让使用跑鞋的用户感觉就像拥有真正的“第二层肌肤”。`,
    },
    {
      name: `4mm-drop`,
      title: `4毫米缓冲厚度`,
      content: `Kalenji的Jogflow 500.1跑鞋拥有4毫米的缓冲厚度，跑步者的前脚掌可放心地碰击地面，该设计可帮助用户以更自然的步伐跑步，同时避免受伤。`,
    },
    {
      name: `eva-foam`,
      title: `EVA泡沫`,
      content: `为了避免足部在鞋底陷入过深，设计团队还打造出了质地柔软的EVA泡沫缓冲鞋垫，以减少对脚踝的冲击力度。`,
    },
    {
      name: `flex-h-sole`,
      title: `Flex H鞋底 `,
      content: `Jogflow 500.1跑鞋采用了Flex H鞋底，可最大程度地保持跑鞋的柔韧度。鞋底上的可弯曲槽口贴合双脚迈出的每一个步伐，让足部的行动更自由，同时提供减震效果。`,
    },
  ]
};
