<template>
  <div
    class="loading-screen"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <clouds-background
      :clouds-config="cloudsConfig"
      context="default"
    >
      <div class="h-100 flex-col justify-center align-center preloading-area">
        <loading-bar
          :config="loadingConfig"
          :progress="progress"
          v-show="isPreloading"
        />
        <div class="loaded-screen" v-show="!isPreloading">
          <div class="logo-wrapper">
            <img :src="kalenjiFlowLogo" alt="kalenji-flow-logo" />
          </div>
          <div class="start-button-wrapper">
            <a class="kalenji-button white-button large-button" @click="close">
              {{ startBtnLabel }}
            </a>
          </div>
        </div>
      </div>
    </clouds-background>
  </div>
</template>

<script>
import CloudsBackground from '@/components/CloudsBackground';
import LoadingBar from '@/components/LoadingBar';

const LOADING_TEXT_DURATION = 350;
const LOADING_TEXT_SECONDARY_DURATION = 5000;

export default {
  components: {
    CloudsBackground,
    LoadingBar,
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    cloudsConfig: {
      type: Array,
      required: true,
      default: () => [],
    },
    loadingConfig: {
      type: Array,
      required: false,
      default: () => [],
    },
    isPreloading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    progress: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },

  data: () => ({
    loadingTextTimeout: null,
    loadingTextSecondaryTimeout: null,
  }),

  computed: {
    backgroundImage() {
      return this.getConfigItem(`background_image`)?.image || ``;
    },
    kalenjiWhiteLogo() {
      return this.getConfigItem(`kalenji_white_logo`)?.image || ``;
    },
    kalenjiFlowLogo() {
      return this.getConfigItem(`kalenji_flow_logo`)?.image || ``;
    },
    startBtnLabel() {
      return this.getConfigItem(`start_btn`)?.title || ``;
    },
  },

  methods: {
    getConfigItem(name) {
      return this.loadingConfig.find(item => item.name === name) || null;
    },
    close() {
      this.$emit(`close`);
    },
    initLoadingText() {
      this.currentLoadingTextNbDots = -1;
      this.nextLoadingText();
    },
    initLoadingTextSecondary() {
      this.currentLoadingTextSecondaryIndex = -1;
      this.nextLoadingTextSecondary();
    },
    nextLoadingText() {
      this.currentLoadingTextNbDots += 1;
      this.loadingTextTimeout = setTimeout(() => this.nextLoadingText(), LOADING_TEXT_DURATION);
    },
    nextLoadingTextSecondary() {
      this.currentLoadingTextSecondaryIndex += 1;
      this.loadingTextSecondaryTimeout = setTimeout(() => this.nextLoadingTextSecondary(), LOADING_TEXT_SECONDARY_DURATION);
    },
  },

  mounted() {
    if (this.visible) {
      this.initLoadingText();
      this.initLoadingTextSecondary();
    }
  },

  beforeDestroy() {
    clearTimeout(this.loadingTextTimeout);
    clearTimeout(this.loadingTextSecondaryTimeout);
  },

  watch: {
    visible(visible) {
      if (visible) {
        this.initLoadingText();
        this.initLoadingTextSecondary();
      } else {
        clearTimeout(this.loadingTextTimeout);
        clearTimeout(this.loadingTextSecondaryTimeout);
        this.currentLoadingTextNbDots = -1;
        this.currentLoadingTextSecondaryIndex = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &, * {
    color: #FFF;
  }

  .start-button-wrapper {
    margin-bottom: 5%;
  }

  &:not(.mobile) {
    display: flex;
    flex-direction: column;
    .logo-wrapper {
      text-align: left;
      padding: 40px 40px 20px;
      img {
        max-width: 380px;
      }
    }

    .preloading-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding: 30px 20px;

      .loaded-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        .logo-wrapper {
          margin: auto 0;
          padding: 0;
        }
      }
    }

    .start-button-wrapper {
      display: inline-block;
    }
  }

  &.mobile {
    display: flex;
    flex-direction: column;

    .logo-wrapper {
      padding: 20px 20px 0px;
      img {
        width: 50%;
      }
    }

    .preloading-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding: 30px 20px;

      .loaded-screen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        .logo-wrapper {
          margin: auto 0;
          padding: 0;
        }
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>

<style lang="scss">

.vue-progress-path .progress {
  stroke: #FFF !important;
  stroke-width: 11px !important;
}

.vue-progress-path .background {
  stroke: #AB1822 !important;
  stroke-width: 11px !important;
}
</style>
