import * as THREE from 'three'

export default class Cam {
	constructor(ap3) {
		this.init(ap3)
	}

	init(ap3) {
		this.ap3 = ap3
		this.params = {
			iRotLon: -91, // Cam. rot. ini. horizontale : -180° à 180°
			iRotAzi: -5, // Cam. rot. ini. Verticale : -90° à 90°
			minAzi: -45, // Cam. angle vert. mini
			maxAzi: 45, // Cam. angle vert. maxi
			speed: 1, // Vitesse de rotation par rapport au scroll
			// amorti: 12, // Amorti des rotations caméra
			safeZoneFactor: 0 // 2.5 // % de la largeur d'écran à balayer avant de démarrer le scroll
		}
		this.iRotLon = this.params.iRotLon * Math.PI/180
		this.iRotAzi = this.params.iRotAzi * Math.PI/180
		this.minAzi = this.params.minAzi * Math.PI/180
		this.maxAzi = this.params.maxAzi * Math.PI/180
		this.target = { x: this.iRotLon, y: this.iRotAzi }
		this.iTarget = { x: this.iRotLon, y: this.iRotAzi }
		this.hGyr0 = new THREE.Object3D()
		this.hCam0 = new THREE.Object3D()
		this.hGyr1 = new THREE.Object3D()
		this.hCam1 = new THREE.Object3D()
		this.cam = new THREE.PerspectiveCamera( 80, this.ap3.sizes.w / this.ap3.sizes.h, 0.1, 11 )
		this.ap3.scene.add(this.hGyr0)
		this.hGyr0.add(this.hCam0)
		this.hCam0.add(this.hGyr1)
		this.hGyr1.add(this.hCam1)
		this.hCam1.add(this.cam)
		this.hCam0.rotation.y = this.iRotLon
		this.hCam1.rotation.x = this.iRotAzi
	}

	resize() {
		this.cam.aspect = this.ap3.sizes.w / this.ap3.sizes.h
		this.cam.updateProjectionMatrix()
	}

	setITarget() {
		this.iTarget.x = this.hCam0.rotation.y
		this.iTarget.y = this.hCam1.rotation.x
	}

	tickTarget() {
		this.target.x = this.iTarget.x + (this.ap3.pointer.pointer.x - this.ap3.pointer.iPointer.x) * Math.PI/2 * this.params.speed
		this.target.y = this.iTarget.y - (this.ap3.pointer.pointer.y - this.ap3.pointer.iPointer.y) * Math.PI/2 * this.params.speed
		this.target.y = Math.min(this.maxAzi - this.hGyr1.rotation.x, Math.max(this.minAzi - this.hGyr1.rotation.x, this.target.y))
		// this.totalAzi = this.hGyr1.rotation.x + this.target.y
		// if(this.totalAzi < this.minAzi) {
		// 	this.target.y += this.minAzi - this.totalAzi
		// } else
		// if(this.totalAzi > this.ap3.cam.maxAzi) {
		// 	this.target.y += this.maxAzi - this.totalAzi
		// } 
	}

	tickCam() {
		// const taux = 2 - Math.exp(this.ap3.time.delta/1000 * this.params.amorti)
		// this.hCam0.rotation.y = this.hCam0.rotation.y * taux + this.target.x * (1 - taux)
		// this.hCam1.rotation.x = this.hCam1.rotation.x * taux + this.target.y * (1 - taux)

		// const taux = Math.pow( 0.99, this.ap3.time.delta / 20 )
		// const taux = 0.99
		// console.log(this.target.y)
		// this.hCam0.rotation.y += (this.target.x - this.hCam0.rotation.y) * 0.05
		// this.hCam1.rotation.x += (this.target.y - this.hCam1.rotation.x) * 0.05

		this.hCam0.rotation.y = this.target.x
		this.hCam1.rotation.x = this.target.y

		if(!this.ap3.pointer.dragging && Math.abs(this.hCam0.rotation.y - this.target.x) < 0.01) {
			this.ap3.time.remEvent('cam')
		}
		// this.ap3.sphere.tick()
	}
}