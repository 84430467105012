export default [
  {
    name: `deep-blue`,
    title: `Diepzeeblauw`,
    sizes: [
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
      { value: 43, price: `34.99€` },
      { value: 44, price: `34.99€` },
      { value: 45, price: `34.99€` },
      { value: 46, price: `34.99€` },
      { value: 47, price: `34.99€` },
    ],
  },
  {
    name: `black`,
    title: `Zwart`,
    sizes: [
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
      { value: 43, price: `34.99€` },
      { value: 44, price: `34.99€` },
      { value: 45, price: `34.99€` },
      { value: 46, price: `34.99€` },
      { value: 47, price: `34.99€` },
    ],
  },
  {
    name: `carbon-grey`,
    title: `Carbongrijs`,
    sizes: [
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
      { value: 43, price: `34.99€` },
      { value: 44, price: `34.99€` },
      { value: 45, price: `34.99€` },
      { value: 46, price: `34.99€` },
      { value: 47, price: `34.99€` },
    ],
  },
  {
    name: `snow-white`,
    title: `Sneeuwwit`,
    sizes: [
      { value: 39, price: `34.99€` },
      { value: 40, price: `34.99€` },
      { value: 41, price: `34.99€` },
      { value: 42, price: `34.99€` },
      { value: 43, price: `34.99€` },
      { value: 44, price: `34.99€` },
      { value: 45, price: `34.99€` },
      { value: 46, price: `34.99€` },
      { value: 47, price: `34.99€` },
    ],
  },
];