export default [
  {
    name: `introduction_tutorial`,
    content: `Esplorare a 360°`,
    cta: `Continuare`,
  },
  {
    name: `game_tutorial`,
    title: `LET'S PLAY`,
    content: `Clicchi per trovare la Jogflow nelle nuvole e avere una possibilità di vincere un equipaggiamento da sport Kalenji`,
    cta: `Continuare`,
  },
]
