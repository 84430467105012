
export default {
  name: `500K.1`,
  sections: [
    {
      name: `eco-design`,
      title: `Éco-conception`,
      content: `Kalenji utilise des matériaux recyclés pour concevoir ses tiges tricotées ainsi que pour ses semelles intérieures et extérieures. La tige tricotée est à 62 % éco-conçue !`,
    },
    {
      name: `3d-knit`,
      title: `KNIT 3D`,
      content: `Kalenji a conçu une tige tricotée en Knit 3D offrant un réel effet "seconde peau" aux coureurs qui portent ce modèle.`,
    },
    {
      name: `4mm-drop`,
      title: `Drop de 4mm`,
      content: `La Jogflow 500.1 de Kalenji est dotée d'un drop de 4 millimètres pour inciter les coureurs à attaquer le sol avec l'avant du pied et par conséquent les aider à adopter une foulée plus naturelle et limiter le risque de blessure.`,
    },
    {
      name: `eva-foam`,
      title: `Mousse EVA`,
      content: `Pour empêcher le pied de s'enfoncer trop  dans la semelle, les équipes de conception ont créé un amorti grâce à une mousse souple en EVA qui réduit l'impact au niveau du talon.`,
    },
    {
      name: `flex-h-sole`,
      title: `Semelle Flex H`,
      content: `La Jogflow 500.1 est dotée d'une semelle Flex H conçue pour garantir une flexibilité maximale de la chaussure. Les encoches de flexion pratiquées sur la semelle procurent une véritable liberté de mouvement au pied tout en facilitant l'absorption des chocs par la semelle, sa fonction première.`,
    },
  ]
};