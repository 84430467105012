export default [
  {
    name: `powdery-beige`,
    colorKey: `beige`,
    icon: require(`@/assets/products/500K/woman/beige/500K_woman_icon.jpg`),
    carousel: [
      require(`@/assets/products/500K/woman/beige/carousel/500K_woman_slide_1.jpg`),
      require(`@/assets/products/500K/woman/beige/carousel/500K_woman_slide_2.jpg`),
      require(`@/assets/products/500K/woman/beige/carousel/500K_woman_slide_3.jpg`),
      require(`@/assets/products/500K/woman/beige/carousel/500K_woman_slide_4.jpg`),
    ],
    sizes: [
      { value: 36, sku: 4206333 },
      { value: 37, sku: 4206334 },
      { value: 38, sku: 4206335 },
      { value: 39, sku: 4206336 },
      { value: 40, sku: 4206337 },
      { value: 41, sku: 4206338 },
      { value: 42, sku: 4206339 },
    ],
  },
  {
    name: `khaki`,
    colorKey: `kaki`,
    icon: require(`@/assets/products/500K/woman/kaki/500K_woman_icon.jpg`),
    carousel: [
      require(`@/assets/products/500K/woman/kaki/carousel/500K_woman_slide_1.jpg`),
      require(`@/assets/products/500K/woman/kaki/carousel/500K_woman_slide_2.jpg`),
      require(`@/assets/products/500K/woman/kaki/carousel/500K_woman_slide_3.jpg`),
      require(`@/assets/products/500K/woman/kaki/carousel/500K_woman_slide_4.jpg`),
    ],
    sizes: [
      { value: 36, sku: 4406492 },
      { value: 37, sku: 4406493 },
      { value: 38, sku: 4406494 },
      { value: 39, sku: 4406495 },
      { value: 40, sku: 4406496 },
      { value: 41, sku: 4406497 },
      { value: 42, sku: 4406498 },
    ],
  },
];
