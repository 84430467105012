<template>
  <div class="flex-col justify-center align-center">
    <div class="weight-bold lang-title">Choose your language</div>
    <div class="flex-grow flex-col justify-center align-center w-100 my-30">
      <div
        v-for="(lang, i) in langs"
        :key="lang.key"
        class="kalenji-button border-button lang-btn"
        :class="{
          'mt-15': i !== 0
        }"
        @click="chooseLang(lang)"
      >
        <div class="w-100">
          <div class="flex-row justify-center align-center">
            {{ lang.shortLabel }} - {{ lang.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-100">
      <a class="kalenji-button white-button large-button" @click="close">
        Back
      </a>
    </div>
  </div>
</template>

<script>
import langs from '@/visit/langs.js';

export default {
  data: () => ({
    langs,
  }),

  methods: {
    chooseLang(lang) {
      this.$emit(`choose-lang`, lang);
    },
    close() {
      this.$emit(`close`);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-btn {
  width: 100%;
  max-width: 300px;
  background: transparent;
}

.lang-title {
  font-size: 20px;
}
</style>
