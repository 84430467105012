export default [
  {
    name: `introduction_tutorial`,
    content: `Explorar en 360°`,
    cta: `Continuar`,
  },
  {
    name: `game_tutorial`,
    title: `¡JUGUEMOS! `,
    content: `Haga clic para encontrar las Jogflow en las nubes y poder ganar un conjunto  Kalenji `,
    cta: `Continuar`,
  },
]
