export default [
  {
    name: `black`,
    title: `黑色`,
    sizes: [
      { value: 39, price: `￥349.90` },
      { value: 40, price: `￥349.90` },
      { value: 41, price: `￥349.90` },
      { value: 42, price: `￥349.90` },
      { value: 43, price: `￥349.90` },
      { value: 44, price: `￥349.90` },
      { value: 45, price: `￥349.90` },
      { value: 46, price: `￥349.90` },
      { value: 47, price: `￥349.90` },
    ],
  },
  {
    name: `khaki`,
    title: `深綠色`,
    sizes: [
      { value: 39, price: `￥349.90` },
      { value: 40, price: `￥349.90` },
      { value: 41, price: `￥349.90` },
      { value: 42, price: `￥349.90` },
      { value: 43, price: `￥349.90` },
      { value: 44, price: `￥349.90` },
      { value: 45, price: `￥349.90` },
      { value: 46, price: `￥349.90` },
      { value: 47, price: `￥349.90` },
    ],
  },
];
