export default [
  {
    name: `greeting_page`,
    content: {
      title: `FÉLICITATIONS !`,
      subtitle: `Vous avez une chance de gagner une tenue Kalenji`,
      cta: `Continuer`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `Merci de votre participation`,
      subtitle: ``,
      cta: `Retourner au showroom`,
    },
  },
  {
    name: `game_form`,
    title: `REMPLIR LE FORMULAIRE POUR PARTICIPER`,
    fields: [
      {
        name: `firstname`,
        placeholder: `Prénom`,
      },
      {
        name: `lastname`,
        placeholder: `Nom`,
      },
      {
        name: `email`,
        placeholder: `E-mail`,
      },
    ],
    formSubmitText: `Tentez votre chance`,
  },
  {
    name: `lose_messages`,
    messages: [
      `Essaye encore`,
      `Une prochaine fois, peut-être ?`,
    ],
  }
];