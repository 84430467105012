export default [
  {
    name: `laurel-green`,
    title: `Laurier vert`,
    sizes: [
      { value: 36, price: `35€` },
      { value: 37, price: `35€` },
      { value: 38, price: `35€` },
      { value: 39, price: `35€` },
      { value: 40, price: `35€` },
      { value: 41, price: `35€` },
      { value: 42, price: `35€` },
    ],
  },
  {
    name: `black`,
    title: `Noir`,
    sizes: [
      { value: 36, price: `35€` },
      { value: 37, price: `35€` },
      { value: 38, price: `35€` },
      { value: 39, price: `35€` },
      { value: 40, price: `35€` },
      { value: 41, price: `35€` },
      { value: 42, price: `35€` },
    ],
  },
  {
    name: `orange`,
    title: `Orange`,
    sizes: [
      { value: 36, price: `35€` },
      { value: 37, price: `35€` },
      { value: 38, price: `35€` },
      { value: 39, price: `35€` },
      { value: 40, price: `35€` },
      { value: 41, price: `35€` },
      { value: 42, price: `35€` },
    ],
  },
  {
    name: `foggy-blue`,
    title: `Gris brume`,
    sizes: [
      { value: 36, price: `35€` },
      { value: 37, price: `35€` },
      { value: 38, price: `35€` },
      { value: 39, price: `35€` },
      { value: 40, price: `35€` },
      { value: 41, price: `35€` },
      { value: 42, price: `35€` },
    ],
  },
];