<template>
  <div id="app">
    <splash-screen
      :visible="isSplashScreenOpened"
      :splashscreen-config="visit.splashScreen"
      :clouds-config="visit.cloudsBackground"
      :lang="lang"
      @choose-lang="langChosen"
      @close="closeSplashScreen"
      v-if="isSplashAndLoadingScreensLoaded"
    />
    <loading-screen
      :is-preloading="isPreloading"
      :clouds-config="visit.cloudsBackground"
      :loading-config="visit.loading"
      :progress="preloadingProgress"
      :visible="isLoadingScreenOpened"
      @close="closeLoadingScreen"
      v-if="isSplashAndLoadingScreensLoaded"
    />
    <audio
      ref="backgroundSound"
      loop="true"
      v-if="currentSound !== null && !isMuted"
    >
      <source :src="currentSound.src" type="audio/mp3">
    </audio>
    <audio
      ref="soundEffect"
      v-if="currentSfx !== null && !isMuted"
    >
      <source :src="currentSfx.src" type="audio/mp3">
    </audio>
    <spaces-visit
      :visit="visit"
      :muted="isMuted || !isTabActive"
      :lang="lang"
      :render="isTabActive"
      :gyro-enabled="isGyroEnabled"
      :gyro-datas="gyroDatas"
      @play-sound="playSound"
      @resume-sound="resumeSound"
      @pause-sound="pauseSound"
      @play-sfx="playSfx"
      @mute="mute"
      @unmute="unmute"
      @toggle-fullscreen="toggleFullscreen"
      v-if="isVisitStarted"
    />
    <intro-video
      :video="introVideo"
      :visible="isIntroVideoOpened"
      @close="introVideoClosed"
    />
    <a class="powered-by-reality" href="https://reality.fr" target="_blank">
      <img src="@/assets/poweredbyreality.png" />
    </a>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Vue from 'vue';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import mergeConfigFiles from '@/lib/mergeConfigFiles'

import loadTexture from '@/three/loadTexture';

import SplashScreen from '@/components/SplashScreen';
import LoadingScreen from '@/components/LoadingScreen';
import IntroVideo from '@/components/IntroVideo';
import SpacesVisit from '@/components/SpacesVisit';

import fonts from '@/visit/fonts.js';
import langs from '@/visit/langs.js';

let DEFAULT_LANG = langs.find(lang => lang.isDefault === true);
if (!DEFAULT_LANG) { DEFAULT_LANG = langs[0]; }

const preloadedScenes = [];
const visitFiles = {}

require.context(`@/visit`, true, /^.*\.js$/)
  .keys()
  .map(fileName => fileName.replace(/^\W*/g, ``))
  .forEach(fileName => visitFiles[fileName] = require(`@/visit/${fileName}`))

const loadVisitConfigItem = (item, lang, isMobile) => {
  const platformFileKey = isMobile ? `.mobile` : ``;
  const langFileKey = `.${lang.key}`;
  const defaultLangFileKey = `.${DEFAULT_LANG.key}`;

  const itemSpecificConfig = visitFiles[`${item}/${item}${platformFileKey}${langFileKey}.js`];
  const itemLangConfig = visitFiles[`${item}/${item}${langFileKey}.js`];
  const itemDefaultLangConfig = visitFiles[`${item}/${item}${defaultLangFileKey}.js`];
  const itemPlatformConfig = platformFileKey ? visitFiles[`${item}/${item}${platformFileKey}.js`] : null;
  const itemGlobalConfig = visitFiles[`${item}/${item}.js`];
  const itemRes = mergeConfigFiles(
    itemGlobalConfig?.default,
    itemPlatformConfig?.default,
    itemDefaultLangConfig?.default,
    itemLangConfig?.default,
    itemSpecificConfig?.default
  );
  return itemRes;
};

function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

window.onpopstate = () => {
  window.location.reload();
};

export default {
  components: {
    SplashScreen,
    LoadingScreen,
    IntroVideo,
    SpacesVisit,
  },

  data: () => ({
    hasPointerCross: process.env.VUE_APP_SHOW_CAMERA_TARGET === `true`,
    hasPreloading: true,
    isSplashAndLoadingScreensLoaded: false,
    isSplashScreenOpened: false,
    isLoadingScreenOpened: false,
    isDryPreloading: false,
    isPreloading: false,
    preloadingProgressElements: [],
    fullscreen: false,
    isVisitStarted: false,
    isIntroVideoOpened: false,
    isMuted: false,
    isGyroEnabled: false,
    gyroDatas: {},
    defaultSoundName: `background`,
    lang: DEFAULT_LANG,
    visit: {},
    currentSoundName: null,
    currentSfxName: `sonar-1`,
    isTabActive: true,
    isSoundPlayingBeforeInactiveTab: true,
  }),

  computed: {
    urlLang() {
      const url = window.location.href
        .replace(/\?.*$/g, ``)
        .replace(/\/$/g, ``);
      const langCandidateKey = url.replace(/.*\/(.*)$/g, `$1`);
      const urlLang = langs.find(lang => lang.key === langCandidateKey);

      return urlLang || null;
    },
    langUrl() {
      return `//${window.location.host}/${this.lang.key}`
    },
    preloadingProgress() {
      const elementsKeys = Object.keys(this.preloadingProgressElements);
      if (elementsKeys.length === 0 || this.isDryPreloading) {
        return 0;
      }
      let totalSize = 0;
      let loadedSize = 0;
      elementsKeys.forEach(key => {
        const element = this.preloadingProgressElements[key];
        if (element.total > 0) {
          totalSize += element.total;
          loadedSize += element.loaded;
        }
      });

      return Math.min((loadedSize / totalSize) * 100, 100);
    },
    introVideo() {
      let video = this.visit.videos.find(video => video.name === `intro-video`);

      return video || null;
    },
    currentSound() {
      if (!this.currentSoundName) {
        return null;
      }

      const sound = this.visit.sounds.find(sound => sound.name === this.currentSoundName);

      return sound || null;
    },
    currentSfx() {
      if (!this.currentSfxName) { return null; }

      const sfx = this.visit.sounds.find(sound => sound.name === this.currentSfxName);

      return sfx || null;
    },
  },

  methods: {
    langChosen(lang) {
      this.setLang(lang);
      this.closeSplashScreen();
    },
    loadVisitConfig() {
      const cloudsBackground = loadVisitConfigItem(`cloudsbackground`, this.lang, this.$breakpoints.isMobile);
      const splashScreen = loadVisitConfigItem(`splashscreen`, this.lang, this.$breakpoints.isMobile);
      const loading = loadVisitConfigItem(`loading`, this.lang, this.$breakpoints.isMobile);
      const scenes = loadVisitConfigItem(`scenes`, this.lang, this.$breakpoints.isMobile);
      const dialogs = loadVisitConfigItem(`dialogs`, this.lang, this.$breakpoints.isMobile);
      const map = loadVisitConfigItem(`map`, this.lang, this.$breakpoints.isMobile);
      const menu = loadVisitConfigItem(`menu`, this.lang, this.$breakpoints.isMobile);
      const sounds = loadVisitConfigItem(`sounds`, this.lang, this.$breakpoints.isMobile);
      const videos = loadVisitConfigItem(`videos`, this.lang, this.$breakpoints.isMobile);
      const icons = loadVisitConfigItem(`icons`, this.lang, this.$breakpoints.isMobile);
      const tutorials = loadVisitConfigItem(`tutorials`, this.lang, this.$breakpoints.isMobile);
      const shoePresentationCommon = loadVisitConfigItem(`shoe_presentation_common`, this.lang, this.$breakpoints.isMobile);
      const shoePresentation500 = loadVisitConfigItem(`shoe_presentation_500`, this.lang, this.$breakpoints.isMobile);
      const shoePresentation500K = loadVisitConfigItem(`shoe_presentation_500k`, this.lang, this.$breakpoints.isMobile);
      const game = loadVisitConfigItem(`game`, this.lang, this.$breakpoints.isMobile);
      const shoes3D = loadVisitConfigItem(`shoes_3d`, this.lang, this.$breakpoints.isMobile);
      const productPageCommon = loadVisitConfigItem(`product_page_common`, this.lang, this.$breakpoints.isMobile);
      const product500Woman = loadVisitConfigItem(`product_500_woman`, this.lang, this.$breakpoints.isMobile);
      const product500Man = loadVisitConfigItem(`product_500_man`, this.lang, this.$breakpoints.isMobile);
      const product500KWoman = loadVisitConfigItem(`product_500k_woman`, this.lang, this.$breakpoints.isMobile);
      const product500KMan = loadVisitConfigItem(`product_500k_man`, this.lang, this.$breakpoints.isMobile);

      this.visit = {
        cloudsBackground,
        splashScreen,
        loading,
        dialogs,
        sounds,
        map,
        menu,
        icons,
        tutorials,
        game,
        shoes3D,
        products: {
          common: productPageCommon,
          '500': {
            woman: product500Woman,
            man: product500Man,
          },
          '500K': {
            woman: product500KWoman,
            man: product500KMan,
          },
        },
        shoePresentation: {
          common: shoePresentationCommon,
          '500': shoePresentation500,
          '500K': shoePresentation500K,
        },
        scenes: scenes.map(scene => {
          const dialogHotspots = [];
          (scene.dialogHotspots || []).forEach(hotspot => {
            const dialog = dialogs.find(dialog => dialog.name === hotspot.to);

            if (dialog) {
              dialogHotspots.push({
                ...hotspot,
                uuid: uuidv4(),
                legend: dialog?.title || null,
              });
            }
          });
          return {
            ...scene,
            uuid: uuidv4(),
            isVisible: false,
            isMounted: false,
            dialogHotspots,
            sceneHotspots: (scene.sceneHotspots || []).map(hotspot => {
              const hotspotScene = scenes.find(scene => scene.name === hotspot.to);

              return {
                ...hotspot,
                uuid: uuidv4(),
                legend: hotspotScene?.title || null,
                xRotation: hotspot.xRotation || 0,
                yRotation: hotspot.yRotation || 0,
                zRotation: hotspot.zRotation || 0,
              };
            }),
            hubHotspots: (scene.hubHotspots || []).map(hotspot => {
              const hotspotScene = scenes.find(scene => scene.name === hotspot.to);

              return {
                ...hotspot,
                uuid: uuidv4(),
                legend: hotspotScene?.title || null,
              };
            }),
          };
        }),
        videos: videos.map(video => ({
          ...video,
          uuid: uuidv4(),
        })),
      };
    },
    trackLoadingProgress(src, abortController) {
      if (!this.isPreloading && !this.isDryPreloading) { return null; }

      return event => {
        const total = this.preloadingProgressElements[src]?.total || event.total;
        Vue.set(this.preloadingProgressElements, src, {
          total,
          loaded: this.isDryPreloading ? 0 : event.loaded,
        });
        if (abortController) {
          abortController.abort();
        }
      }
    },
    async downloadItem(src) {
      if (!src || !src.startsWith(`/`))  {
        return src;
      }
      let abortController = null;
      if (this.isDryPreloading) {
        abortController = new AbortController()
      }
      let data = null;
      try {
        const res = await axios.get(src, {
          responseType: `blob`,
          signal: abortController?.signal,
          onDownloadProgress: this.trackLoadingProgress(src, abortController),
        });
        data = res.data;
      } catch (e) {} // eslint-disable-line

      return data;
    },
    async preloadFonts() {
      if (!fonts.length) { return Promise.resolve(); }

      await Promise.all(fonts.map(async font => {
        const { data } = await axios.get(font.url, { responseType: `arraybuffer` });
        const base64Font = arrayBufferToBase64(data);
        const style = document.createElement(`style`);
        style.innerText = `
          @font-face {
            font-family: '${font.name}';
            font-weight: ${font.weight};
            src: url('data:font/truetype;charset=utf-8;base64,${base64Font}');
          }
        `.replace(/[\r\n]/g, ``).replace(/ +/g, ` `);
        document.head.appendChild(style);
      }));
      const style = document.createElement(`style`);
      style.innerText = `
        html, body, #app {
          font-family: ${fonts[0].name}, Avenir, Helvetica, Arial, sans-serif;
        }
      `.replace(/[\r\n]/g, ``).replace(/ +/g, ` `);
      document.head.appendChild(style);
    },
    async preloadImage(image) {
      const data = await this.downloadItem(image);
      if (data) {
        const base64Image = URL.createObjectURL(data);

        return base64Image;
      }

      return image;
    },
    preloadDialogImages(dialog) {
      return Promise.all((dialog.images || []).map(async image => {
        const base64Image = await this.preloadImage(image.src);
        image.$src = image.src;
        image.src = base64Image;
      }));
    },
    async preloadDialogVideo(dialog) {
      if (dialog.video) {
        const base64Video = await this.preloadVideo(dialog.video);
        dialog.$video = dialog.video;
        dialog.video = base64Video;
      }

      return Promise.resolve();
    },
    async preloadShoes3DAssets() {
      await Promise.all(this.visit.shoes3D.assets.map(async asset => {
        if (!asset.url || asset.url.startsWith(`data`)) { return Promise.resolve(); }
        let base64Data = asset.url;
        const data = await this.downloadItem(asset.url);
        if (data) {
          base64Data = URL.createObjectURL(data);
        }
        asset.$url = asset.url;
        asset.url = base64Data;
      }))
    },
    async preloadShoes3DEnvMaps() {
      await Promise.all(this.visit.shoes3D.envMaps.map(async envMap => {
        if (!envMap.url || envMap.url.startsWith(`data`)) { return Promise.resolve(); }
        let base64Data = envMap.url;
        const data = await this.downloadItem(envMap.url);
        if (data) {
          base64Data = URL.createObjectURL(data);
          if (envMap.url.endsWith(`.glb`)) {
            base64Data = arrayBufferToBase64(data);
          } else {
            base64Data = URL.createObjectURL(data);
          }
        }
        envMap.$url = envMap.url;
        envMap.url = base64Data;
      }))
    },
    preloadShoes3D() {
      return Promise.all([
        this.preloadShoes3DAssets(),
        this.preloadShoes3DEnvMaps(),
      ]);
    },
    preloadShoesPages() {
      const promises = [];

      promises.push(Promise.all(this.visit.products[`500`].woman.map(product => {
        return Promise.all(product.carousel.map(async (image, i) => {
          const base64Image = await this.preloadImage(image);
          product.carousel[i] = base64Image;
        }))
      })));
      promises.push(Promise.all(this.visit.products[`500`].man.map(product => {
        return Promise.all(product.carousel.map(async (image, i) => {
          const base64Image = await this.preloadImage(image);
          product.carousel[i] = base64Image;
        }))
      })));
      promises.push(Promise.all(this.visit.products[`500K`].woman.map(product => {
        return Promise.all(product.carousel.map(async (image, i) => {
          const base64Image = await this.preloadImage(image);
          product.carousel[i] = base64Image;
        }))
      })));
      promises.push(Promise.all(this.visit.products[`500K`].man.map(product => {
        return Promise.all(product.carousel.map(async (image, i) => {
          const base64Image = await this.preloadImage(image);
          product.carousel[i] = base64Image;
        }))
      })));

      return Promise.all(promises);
    },
    preloadDialogs() {
      return Promise.all(this.visit.dialogs.map(dialog => {
        const promises = [this.preloadDialogImages(dialog)];
        promises.push(this.preloadDialogVideo(dialog));

        return Promise.all(promises);
      }))
    },
    async preloadScene(scene) {
      let sceneToLoad = scene;
      if (typeof sceneToLoad === `string`) {
        sceneToLoad = this.visit.scenes.find(scene => scene.name === sceneToLoad);
      }
      if (!sceneToLoad || preloadedScenes.indexOf(sceneToLoad.name) !== -1) {
        return Promise.resolve();
      }
      if (sceneToLoad?.image) {
        await loadTexture(sceneToLoad.image)
      } else if (sceneToLoad?.video) {
        const base64Video = await this.preloadVideo(sceneToLoad.video);
        sceneToLoad.$video = sceneToLoad.video;
        sceneToLoad.video = base64Video;
        await this.create360VideoElement(sceneToLoad);
      }

      if (sceneToLoad.sphereGlb) {
        let base64Data = sceneToLoad.sphereGlb;
        const data = await this.downloadItem(sceneToLoad.sphereGlb);
        if (data) {
          base64Data = URL.createObjectURL(data)
        }
        sceneToLoad.$sphereGlb = sceneToLoad.sphereGlb;
        sceneToLoad.sphereGlb = base64Data;
      }

      if (sceneToLoad.gameImage) {
        const base64Image = await this.preloadImage(sceneToLoad.gameImage);
        sceneToLoad.$gameImage = sceneToLoad.gameImage;
        sceneToLoad.gameImage = base64Image;
      }

      if (sceneToLoad.clouds?.length > 0) {
        await Promise.all(sceneToLoad.clouds.map(async cloud => {
          const base64Image = await this.preloadImage(cloud.image);
          cloud.$image = cloud.image;
          cloud.image = base64Image;
        }));
      }

      if (!this.isDryPreloading) {
        preloadedScenes.push(sceneToLoad.name);
      }

      return Promise.resolve();
    },
    preloadScenes() {
      return Promise.all(this.visit.scenes.map(async scene => {
        return this.preloadScene(scene);
      }))
    },
    preloadSounds() {
      return Promise.all(this.visit.sounds.map(async sound => {
        if (sound.name === `background`) { return Promise.resolve(); }
        let base64Data = sound.src;
        const data = await this.downloadItem(sound.src);
        if (data) {
          base64Data = URL.createObjectURL(data)
        }
        sound.$src = sound.src;
        sound.src = base64Data;
      }));
    },
    async preloadVideo(src) {
      let base64Video = src;
      const data = await this.downloadItem(src);
      if (data) {
        base64Video = URL.createObjectURL(data);
      }

      return base64Video;
    },
    async preloadIntroVideo() {
      let video = this.visit.videos.find(video => video.name === `intro-video`);
      if (video) {
        const base64Video = await this.preloadVideo(video.src);
        video.$src = video.src;
        video.src = base64Video;
      }
    },
    async preloadSphereVideo(name) {
      let video = this.visit.videos.find(video => video.name === name);
      if (video) {
        const base64Video = await this.preloadVideo(video.src);
        video.$src = video.src;
        video.src = base64Video;
      }
    },
    preloadVideos() {
      return Promise.all(this.visit.videos.map(async video => {
        if (video.name !== `intro-video` && video.src && !video.src.startsWith(`data`) && !video.src.startsWith(`blob`)) {
          const base64Video = await this.preloadVideo(video.src);
          video.$src = video.src;
          video.src = base64Video;
        }
      }));
    },
    preloadIcons() {
      return Promise.all(Object.keys(this.visit.icons).map(async key => {
        this.visit.icons[key] = await this.preloadImage(this.visit.icons[key]);
      }));
    },
    create360VideoElement(scene) {
      return new Promise(resolve => {
        const id = `video-scene-${scene.uuid}`;
        let videoElement = document.getElementById(id);
        let videoElementSource = null;
        let currentTime = 0;
        let isPaused = true;
        if (!videoElement) {
          videoElement = document.createElement(`video`);
          videoElementSource = document.createElement(`source`);
          videoElementSource.type = `video/mp4`;
          videoElement.id = id;
          videoElement.style.display = `none`;
          videoElement.setAttribute(`playsinline`, true);
          videoElement.setAttribute(`webkit-playsinline`, true);
          videoElement.setAttribute(`muted`, true);
          videoElement.setAttribute(`default-muted`, !(scene.hasSound === true));
          if (!scene.videoEndsToScene) {
            videoElement.setAttribute(`loop`, true);
          }
          videoElement.appendChild(videoElementSource);
          document.body.appendChild(videoElement);
        } else {
          videoElementSource = videoElement.querySelector(`source`);
          currentTime = videoElement.currentTime;
          isPaused = videoElement.paused;
        }
        videoElement.setAttribute(`currentTime`, currentTime);
        videoElementSource.setAttribute(`src`, scene.video);
        setTimeout(() => {
          videoElement.load();
          if (!isPaused) {
            videoElement.play();
          }
        });
        setTimeout(() => {
          resolve(videoElement);
        }, 1000);
      });
    },
    create360VideoElements() {
      return Promise.all(this.visit.scenes.map(scene => {
        if (scene.image) { return Promise.resolve(); }
        return this.create360VideoElement(scene);
      }));
    },
    async wait(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
    async preloadSplashAndLoadingScreens() {
      this.isSplashAndLoadingScreensLoaded = false;
      await Promise.all([
        Promise.all(this.visit.cloudsBackground.map(async item => {
          if (item.image) {
            const base64Image = await this.preloadImage(item.image);
            item.$image = item.image;
            item.image = base64Image;
          } else if (item.elems) {
            await Promise.all(item.elems.map(async elem => {
              if (!elem.path) { return Promise.resolve(); }
              const base64Image = await this.preloadImage(elem.path);
              elem.$path = elem.path;
              elem.path = base64Image;
            }))
          } else {
            return Promise.resolve();
          }
        })),
        Promise.all(this.visit.splashScreen.map(async item => {
          if (!item.image || item.image.startsWith(`data`)) { return Promise.resolve(); }
          const base64Image = await this.preloadImage(item.image);
          item.$image = item.image;
          item.image = base64Image;
        })),
        Promise.all(this.visit.loading.map(async item => {
          if (!item.image || item.image.startsWith(`data`)) { return Promise.resolve(); }
          const base64Image = await this.preloadImage(item.image);
          item.$image = item.image;
          item.image = base64Image;
        })),
      ]);
      this.isSplashAndLoadingScreensLoaded = true;
    },
    preloadMandatoryElements() {
      return Promise.all([
        this.preloadIntroVideo(),
        this.preloadSounds(),
        this.preloadSphereVideo(`technicity-shoes`),
        this.preloadSphereVideo(`injuries-prevention`),
        this.preloadScene(`main`),
        this.preloadShoes3D(),
      ]);
    },
    async preload() {
      if (this.hasPreloading) {
        this.isPreloading = true;
        this.isDryPreloading = true;
        await this.wait(100);
        await this.preloadMandatoryElements();
        this.isDryPreloading = false;
        await this.wait(10);
        await this.preloadMandatoryElements();
      }
      await this.create360VideoElements();
      await this.wait(10);
      this.isPreloading = false;
      if (this.hasPreloading) {
        this.preloadShoesPages();
        this.preloadVideos();
      }
    },
    closeSplashScreen() {
      this.isLoadingScreenOpened = true;
      this.isSplashScreenOpened = false;
      this.preload();
      history.pushState({}, ``, this.langUrl);
    },
    async closeLoadingScreen() {
      this.isLoadingScreenOpened = false;
      if (!this.$breakpoints.isMobile && this.fullscreen) {
        this.enterFullscreen();
      }
      this.enableGyro();
      await this.wait(100);
      this.playIntroVideo();
      setTimeout(() => {
        this.playSound(this.defaultSoundName);
      }, 100);
      setTimeout(() => {
        this.isVisitStarted = true;
      }, 1000);
      this.$gtagEvent(`Start`);
    },
    enterFullscreen() {
      const fullscreenElement = document.documentElement;
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        fullscreenElement.msRequestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        fullscreenElement.webkitRequestFullscreen();
      }
    },
    exitFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozExitFullScreen) {
          document.mozExitFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      } catch (e) {} // eslint-disable-line
    },
    toggleFullscreen() {
      if (document.fullscreenElement !== null) {
        this.exitFullscreen();
      } else {
        this.enterFullscreen();
      }
    },
    playIntroVideo() {
      this.isIntroVideoOpened = true;
    },
    introVideoClosed() {
      this.isIntroVideoOpened = false;
      // this.startVisit();
    },
    pauseSound() {
      if (!this.$refs[`backgroundSound`]) { return; }
      setTimeout(() => {
        try {
          this.$refs[`backgroundSound`].pause();
        } catch (e) {} // eslint-disable-line
      });
    },
    resumeSound() {
      if (!this.$refs[`backgroundSound`]) { return; }
      setTimeout(() => {
        try {
          this.$refs[`backgroundSound`].play();
        } catch (e) {} // eslint-disable-line
      });
    },
    async playSound(soundName) {
      this.currentSoundName = soundName;
      await this.wait();
      this.resumeSound();
    },
    async playSfx(type) {
      const soundIndex = Math.ceil(Math.random() * 3);

      switch (type) {
        case `sphere-sfx`:
          this.currentSfxName = `sonar-${soundIndex}`;
          break;
        case `interface`:
          this.currentSfxName = `interface-click`;
          break;
        case `multimedia`:
          this.currentSfxName = `multimedia-click-${soundIndex}`;
          break;
        default:
          return;
      }

      if (this.$refs[`soundEffect`] && this.currentSfx !== null) {
        this.$refs[`soundEffect`].load();
        setTimeout(() => {
          try {
            this.$refs[`soundEffect`].play();
          } catch (e) {} // eslint-disable-line
        });
      }
    },
    mute() {
      this.isMuted = true;
    },
    unmute() {
      this.isMuted = false;
      this.resumeSound();
    },

    setLang(lang) {
      this.lang = lang;
      this.$gtagLang(lang.key);
      this.loadVisitConfig();
    },
    handleVisibilityChange() {
      if (document.visibilityState === `hidden`) {
        this.isTabActive = false;
      } else  {
        this.isTabActive = true;
      }
    },
    async enableGyro() {
      if (!this.$breakpoints.isMobileDevice) { return; }

      if (window.DeviceOrientationEvent && window.DeviceOrientationEvent.requestPermission) {
        try {
          await window.DeviceOrientationEvent.requestPermission();
          this.isGyroEnabled = true;
        } catch (e) {
          console.log(e);
        } // eslint-disable-line
      } else if(window.DeviceOrientationEvent) {
        this.isGyroEnabled = true;
      }
    },
    deviceOrientationChanged({ alpha, gamma, beta }) {
      this.gyroDatas = { alpha, gamma, beta };
    },
  },

  created() {
    this.setLang(this.urlLang || DEFAULT_LANG);
  },

  beforeMount() {
    this.preloadFonts();
  },

  async mounted() {
    document.addEventListener(`visibilitychange`, this.handleVisibilityChange, false);
    await this.preloadSplashAndLoadingScreens();
    await this.wait();
    if (!this.urlLang) {
      setTimeout(() => this.isSplashScreenOpened = true, 800);
    } else {
      this.isLoadingScreenOpened = true;
      await this.preload();
    }
  },

  watch: {
    isTabActive(isTabActive) {
      if (!isTabActive) {
        this.isSoundPlayingBeforeInactiveTab = false;
        try {
          this.isSoundPlayingBeforeInactiveTab = !this.$refs[`backgroundSound`].paused;
        } catch (e) {
          console.log(e);
        } // eslint-disable-line
        this.pauseSound();
      } else if (this.isSoundPlayingBeforeInactiveTab) {
        this.resumeSound();
      }
    },
    async isGyroEnabled(isGyroEnabled) {
      if (isGyroEnabled) {
        try {
          window.addEventListener(`deviceorientation`, this.deviceOrientationChanged, true);
        } catch (e) {} // eslint-disable-line
      } else {
        try {
          window.removeEventListener(`deviceorientation`, this.deviceOrientationChanged, true);
        } catch (e) {} // eslint-disable-line
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.powered-by-reality {
  position: fixed;
  bottom: 7px;
  right: 9px;
  height: 16px;
  text-decoration: none;
  img {
    height: 100%;
  }
}
</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

button {
  margin: 0px;
}

.kalenji-button {
  border-radius: 100px;
  background-color: #ae9e91;
  text-align: center;
  padding: 0px 16px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  height: 44px;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;

  transition: background .2s, color .2s;

  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #ae9e91;
  }

  &.white-button {
    background-color: #FFF;
    color: #ae9e91;
    border: none;
  }

  &.large-button {
    height: 50px;
    padding: 0px 32px;
    font-size: 16px;
  }

  &.fab-button {
    width: 60px;
    height: 60px;
  }
}

.border-button {
  border: 1px solid #FFF;
  color: inherit;
}

img.lang-flag {
  max-height: 15px;
}

.clickable,
.close-button {
  cursor: pointer !important;
}
</style>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html, body, #app {
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  background-color: #FFF;
  overflow: hidden;
  touch-action: pan-x pan-y;
}

* {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row,
.flex-col {
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }
}

.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-stretch {
  align-self: stretch;
}

.flex-grow {
  flex-grow: 1;
}

.d-block {
  display: block;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-80 {
  width: 80%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}

.pa-0 {
  padding: 0px !important;
}

.pa-3 {
  padding: 3px !important;
}

.pa-5 {
  padding: 5px !important;
}

.pa-10 {
  padding: 10px !important;
}

.pa-15 {
  padding: 15px !important;
}

.pa-20 {
  padding: 20px !important;
}

.pa-25 {
  padding: 25px !important;
}

.pa-30 {
  padding: 30px !important;
}

.pa-35 {
  padding: 35px !important;
}

.pa-40 {
  padding: 40px !important;
}

.pa-45 {
  padding: 45px !important;
}

.pa-50 {
  padding: 50px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.ma-0 {
  margin: 0px !important;
}

.ma-3 {
  margin: 3px !important;
}

.ma-5 {
  margin: 5px !important;
}

.ma-10 {
  margin: 10px !important;
}

.ma-15 {
  margin: 15px !important;
}

.ma-20 {
  margin: 20px !important;
}

.ma-25 {
  margin: 25px !important;
}

.ma-30 {
  margin: 30px !important;
}

.ma-35 {
  margin: 35px !important;
}

.ma-40 {
  margin: 40px !important;
}

.ma-45 {
  margin: 45px !important;
}

.ma-50 {
  margin: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.mx-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.weight-thin {
  font-weight: 100;
}
.weight-extra-light {
  font-weight: 200;
}
.weight-light {
  font-weight: 300;
}
.weight-regular {
  font-weight: 400;
}
.weight-medium {
  font-weight: 500;
}
.weight-semi-bold {
  font-weight: 600;
}
.weight-bold {
  font-weight: 700;
}
.weight-extra-bold {
  font-weight: 800;
}
.weight-black {
  font-weight: 900;
}
</style>
