export default [
  {
    name: `greeting_page`,
    content: {
      title: `恭喜！`,
      subtitle: `您有机会赢取Kalenji套装`,
      cta: `继续`,
    },
  },
  {
    name: `thank_page`,
    content: {
      title: `感谢您的参与`,
      subtitle: ``,
      cta: `返回展厅`,
    },
  },
  {
    name: `game_form`,
    title: `填写表格以继续`,
    fields: [
      {
        name: `firstname`,
        placeholder: `名`,
      },
      {
        name: `lastname`,
        placeholder: `姓`,
      },
      {
        name: `email`,
        placeholder: `电子邮箱`,
      },
    ],
    formSubmitText: `试试您的运气`,
  },
  {
    name: `lose_messages`,
    messages: [
      `说不定下次可以？`,
      
    ],
  }
];
