export default [
  {
    name: `laurel-green`,
    title: `绿色`,
    sizes: [
      { value: 36, price: `￥299.90` },
      { value: 37, price: `￥299.90` },
      { value: 38, price: `￥299.90` },
      { value: 39, price: `￥299.90` },
      { value: 40, price: `￥299.90` },
      { value: 41, price: `￥299.90` },
      { value: 42, price: `￥299.90` },
    ],
  },
  {
    name: `black`,
    title: `黑色的`,
    sizes: [
      { value: 36, price: `￥299.90` },
      { value: 37, price: `￥299.90` },
      { value: 38, price: `￥299.90` },
      { value: 39, price: `￥299.90` },
      { value: 40, price: `￥299.90` },
      { value: 41, price: `￥299.90` },
      { value: 42, price: `￥299.90` },
    ],
  },
  {
    name: `orange`,
    title: `石英粉`,
    sizes: [
      { value: 36, price: `￥299.90` },
      { value: 37, price: `￥299.90` },
      { value: 38, price: `￥299.90` },
      { value: 39, price: `￥299.90` },
      { value: 40, price: `￥299.90` },
      { value: 41, price: `￥299.90` },
      { value: 42, price: `￥299.90` },
    ],
  },
  {
    name: `foggy-blue`,
    title: `迷雾色/奶油粉`,
    sizes: [
      { value: 36, price: `￥299.90` },
      { value: 37, price: `￥299.90` },
      { value: 38, price: `￥299.90` },
      { value: 39, price: `￥299.90` },
      { value: 40, price: `￥299.90` },
      { value: 41, price: `￥299.90` },
      { value: 42, price: `￥299.90` },
    ],
  },
];
