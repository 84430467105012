import * as THREE from 'three'

export default class Renderer {
	constructor(ap3) {
		this.init(ap3)
		this.setRenderer()
	}

	init(ap3) {
		this.ap3 = ap3
	}

	setRenderer() {
		this.renderer = new THREE.WebGLRenderer({ canvas: this.ap3.canvas, antialias: true })
		// this.renderer.shadowMap.enabled = true
		this.renderer.autoClear = false
		this.renderer.preserveDrawingBuffer = true
		this.renderer.localClippingEnabled = true
		this.renderer.setSize(this.ap3.sizes.w, this.ap3.sizes.h)
		this.renderer.setPixelRatio(Math.min(this.ap3.sizes.pr, 2))
	}

	resize() {
		this.renderer.setSize(this.ap3.sizes.w, this.ap3.sizes.h)
		this.renderer.setPixelRatio(Math.min(this.ap3.sizes.pr, 2))
	}

	tick() { this.renderer.render(this.ap3.scene, this.ap3.cam.cam) }
}